import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {
  CollectionTemplate,
  DocumentAccess,
  DocumentAccessService,
  DocumentsRepository,
  ID,
  IdService,
  UserService,
  UsersSelectors
} from "@qid/common";
import {AccountType} from "@qid/core";
import {Store} from "@ngrx/store";
import {firstValueFrom, Observable, of} from "rxjs";
import {Participant, Share} from "@qid/common";

@Component({
  selector: "share-participant-documents",
  templateUrl: "./share-participant-documents.component.html",
  styleUrls: ["./share-participant-documents.component.scss"]
})
export class ShareParticipantDocumentsComponent implements OnInit {

  @Input() participant: Participant;
  @Output() participantChange: EventEmitter<Participant> = new EventEmitter<Participant>();
  @Input() template: CollectionTemplate;
  @Input() share: Share;
  @Input() deleteButton?: boolean = true
  @Output() remove: EventEmitter<any> = new EventEmitter<any>();

  public authenticatedUser$ = this.store.select(UsersSelectors.selectAuthenticatedUser)

  public existingDocuments$: Observable<ID[]> = new Observable<ID[]>()

  public documentsAccess: DocumentAccess[] = [];

  public static optionLabel = {
    anyOne: "Any one is required",
    optional: "Following IDs are optional to share"
  };


  constructor(private idService: IdService,
              private userService: UserService,
              private store: Store,
              private documentRepository: DocumentsRepository,
              private documentAccessService: DocumentAccessService
  ) {
  }

  ngOnInit() {
    this.initialize();
    this.documentRepository.init()
  }

  async initialize() {
    await this.fetchEntity()
    await this.fetchExistingDocuments();
    this.applyTemplate();
    this.participant.optionalLabel = this.getOptionLabel();
  }

  async fetchEntity() {
    switch (this.participant.accountType) {
      case AccountType.user :
        this.participant.account = await this.userService.getUserById(this.participant.account_id);
        break;
      default :
        return {}
    }
  }

  async fetchExistingDocuments() {
    const user = await firstValueFrom(this.authenticatedUser$)
    if (user._id == this.participant.account_id) {
      this.existingDocuments$ = this.documentRepository.activeAccountRecords$;
      return;
    }

    const res = await this.idService.findAllByOwnerAndCreatedBy(this.participant.account._id);
    if (res) {
      this.existingDocuments$ = of(res);
    }
  }

  public applyTemplate() {
    if (!this.template) {
      return;
    }
    this.participant.mandatoryIds = [];
    this.participant.optionalIds = [];

    let acceptedIds = this.template.acceptedIds;

    for (let field of acceptedIds) {
      if (field.isMandatory) {
        this.participant.mandatoryIds.push(JSON.parse(JSON.stringify(field)));
        continue;
      }
      this.participant.optionalIds.push(JSON.parse(JSON.stringify(field)));
    }
  }

  onIdUpload() {
    this.participantChange.emit(this.participant);
  }

  removeParticipant = () => {
    this.remove.emit();
    return true;
  };

  getOptionLabel() {
    const isNoMandatory = this.participant.mandatoryIds.length < 1;
    const optionalIds = this.participant.optionalIds.length;

    if (isNoMandatory) {
      return optionalIds > 1 ? ShareParticipantDocumentsComponent.optionLabel.anyOne : "";
    }

    if (optionalIds == 1) {
      return ShareParticipantDocumentsComponent.optionLabel.optional;
    }

    if (optionalIds > 1) {
      return ShareParticipantDocumentsComponent.optionLabel.anyOne
    }
  }
}

import {Component, Input} from "@angular/core";
import {ModalController} from "@ionic/angular";
import {ImageUtil} from "@qid/core";
import {PinchToZoomComponent} from "@qid/super";
import {ScannedImage} from "../../../ocr";
import {DocumentAccess, DocumentAccessService} from "../../../document-access";

@Component({
  selector: 'app-view-multiple-documents',
  templateUrl: './view-multiple-documents.page.html',
  styleUrls: ['./view-multiple-documents.page.scss'],
})
export class ViewMultipleDocumentsPage {

  @Input() document_ids: string[] = [];
  documentAccesses: DocumentAccess[] = []

  constructor(
              private modalController: ModalController,
              private documentAccessService: DocumentAccessService
  ) {
  }

  ngOnInit() {
    this.loadDocuments();
  }

  async loadDocuments() {

    if (!this.document_ids) {
      return
    }

    for (let _id of this.document_ids) {
      const access = await this.documentAccessService.findByDocumentId(_id)
      this.documentAccesses.push(access.data[0])
    }

  }

  getImage(url: string) {
    return ImageUtil.getImageSource(url);
  }

  async viewPhoto(image: ScannedImage) {
    const modal = await this.modalController.create({
      component: PinchToZoomComponent,
      componentProps: {
        imageUrl: image.imageUrl
      },
      animated: false
    })
    modal.present();
  }

  close() {
    this.modalController.dismiss();
  }
}

import {Component, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'gateway-authentication',
  templateUrl: './gateway-authentication.component.html',
  styleUrl: './gateway-authentication.component.scss',
})
export class GatewayAuthenticationComponent {

  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>()

  onLogin(user) {
    this.onSubmit.emit(user)
  }


}

<ion-header mode="ios">
  <ion-toolbar>
    <ion-button slot="start" fill="clear" (click)="close()">Close</ion-button>
    <ion-title>{{id?.sid?.name}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>

  <div sxPadding="medium" *ngIf="!documentData">
    <ion-skeleton-text sxRadius="medium" sxWidth="full" sxHeight="180px" [animated]="true"></ion-skeleton-text>
  </div>

  <div sxWidth="full" *ngIf="documentData">

    <div sxPadding="medium" *ngIf="!(isOwnerView$ | async)">
      <ion-skeleton-text *ngIf="!documentAccess" sxWidth="full" sxRadius="medium" sxHeight="80px"></ion-skeleton-text>
      <div *ngIf="documentAccess" sxRow sxDistribution="spaceBetween"
           sxBackground="light" sxRadius="medium" sxGap="10px" sxPadding="medium" sxAlign="center">
        <avatar-round [autoBase]="false" size="50" [imageUrl]="ownerImage" [title]="documentAccess?.documentOwnerAccount?.name"></avatar-round>
        <div sxWidth="fill" sxCol sxGap="4px">
          <p sxTextSize="medium" sxMargin="0" sxColor="three">{{documentAccess?.documentOwnerAccount?.name}}</p>
          <p sxTextSize="small" sxMargin="0" sxColor="two" sxRow sxAlign="center" sxGap="4px" *ngIf="documentAccess?.documentOwnerAccount?.email?.length > 0">
            <ion-icon name="mail-outline"></ion-icon>{{documentAccess?.documentOwnerAccount?.email}}</p>
          <div sxTextSize="small" sxMargin="0" sxColor="two" sxRow sxAlign="center" sxGap="4px" *ngIf="documentAccess?.documentOwnerAccount?.phoneNumber?.length > 0">
            <ion-icon name="call-outline" ></ion-icon>{{documentAccess?.documentOwnerAccount?.phoneNumber | formatPhone}}</div>
        </div>
      </div>
    </div>

    <sid-view *ngIf="documentData?.verifyTime" [id]="id"></sid-view>

    <div sxPadding="medium" *ngIf="documentData" sxCol sxGap="10px">
      <div *ngFor="let image of documentData?.scannedImages"
           class="image-wrapper"
           [style.backgroundImage]="'url(' + getImage(image.imageUrl) + ')'"
           (click)="viewPhoto(image)" sxRow sxAlign="end" sxDistribution="end" sxPadding="medium">

        <div sxRadius="25px" sxBackground="base-shade" sxPadding="8px" sxRow sxGap="10px">
          <ion-icon (click)='share(image)' sxCircle="20px" sxBackground="light" sxPadding="small" sxCursor="pointer"
                    name='share-social-outline'></ion-icon>
          <ion-icon (click)="shareToLocal(image)" sxCircle="20px" sxBackground="light" sxPadding="small"
                    sxCursor="pointer" name="download-outline"></ion-icon>
        </div>
      </div>
    </div>

    <!--  <div sxRow sxGap="10px" sxPadding="medium">-->

    <!--    <div sxBackground="light" sxRadius="medium" sxPadding="medium" sxWidth="fill" sxCol sxGap="10px" sxAlign="center">-->
    <!--      <ion-icon sxTextSize="30px" name="image-outline"></ion-icon>-->
    <!--      Download-->
    <!--    </div>-->
    <!--    <div sxBackground="light" sxRadius="medium" sxPadding="medium" sxWidth="fill" sxCol sxGap="10px" sxAlign="center">-->
    <!--      <ion-icon sxTextSize="30px" name="share-outline"></ion-icon>-->
    <!--      Share-->
    <!--    </div>-->
    <!--    <div sxBackground="light" sxRadius="medium" sxPadding="medium" sxWidth="fill" sxCol sxGap="10px" sxAlign="center">-->
    <!--      <ion-icon sxTextSize="30px" name="create-outline"></ion-icon>-->
    <!--      Update-->
    <!--    </div>-->
    <!--  </div>-->

    <div sxPadding="medium" sxCol sxAlign="stretch" sxGap="10px">
      <div *ngIf="documentData.idNumber" sxCol sxRadius="medium"
           sxPadding="10px medium" sxBackground="light" sxGap="5px">
        <p sxTextAlign="left" sxMargin="0" sxTextSize="12px">{{id?.sid?.name ?? 'Document'}} Number</p>
        <h3 sxMargin="0" sxTextSize="18px">{{documentData?.idNumber}}</h3>
      </div>

      <div *ngIf="accessRemark" sxCol sxRadius="medium"
           sxPadding="10px medium" sxBackground="light" sxGap="5px">
        <p sxTextAlign="left" sxMargin="0" sxTextSize="12px">Remark</p>
        <h3 sxMargin="0" sxTextSize="18px">{{accessRemark}}</h3>
      </div>

      <div *ngIf="documentData?.manualFields && documentData?.manualFields?.length > 0" sxCol
           sxAlign="stretch" sxBackground="light"
           sxRadius="medium">

        <div *ngFor="let field of documentData.manualFields" sxCol sxBorderWidth="0 0 1px 0" sxBorderColor="one"
             sxPadding="10px medium"
             sxGap="5px">
          <p sxTextAlign="left" sxMargin="0" sxTextSize="12px">{{field.label}}</p>
          <h3 sxMargin="0" sxTextSize="18px">{{field.value}}</h3>
        </div>
      </div>
    </div>

    <div sxPadding="medium" *ngIf="(isOwnerView$ | async)" sxCol sxGap="10px">

      <div *ngIf="sharedToAccounts?.length > 0">
        <h1 sxTextSize="medium" sxMargin="0 10px 10px">Shared with</h1>
        <div sxRadius="medium" sxBackground="light">
          <ion-list *ngFor="let account of sharedToAccounts">
            <ion-item>
              <avatar-round slot="start" [size]="25" [title]="account?.name"
                            [imageUrl]="account?.displayPictureUrl"></avatar-round>
              <ion-label>{{account?.name}}</ion-label>
            </ion-item>
          </ion-list>
        </div>
      </div>

      <div sxCol sxRadius="medium"
           sxPadding="10px medium" sxBackground="light" sxGap="5px">
        <p sxTextAlign="left" sxMargin="0" sxTextSize="12px">Created on</p>
        <h3 sxMargin="0" sxTextSize="18px">{{id?.createTime | date : 'medium'}}</h3>
      </div>

      <ion-list>
        <ion-item *ngIf="(activeAccount$ | async)?._id == id?.account_id " (click)="updateDocumentClicked()">
          <ion-icon name="create-outline"></ion-icon>
          <ion-label>Update Id</ion-label>
        </ion-item>
      </ion-list>

    </div>

    <div *ngIf="documentAccess?.apiAccessRemarks?.length > 0" sxCol sxGap="10px" sxPadding="medium">
      <h1 sxTextSize="medium" sxMargin="0 10px 0" sxColor="two">Access History</h1>
      <div *ngFor="let apiAccessRemark of documentAccess.apiAccessRemarks" sxRow sxDistribution="spaceBetween"
           sxAlign="center"
           sxBackground="light" sxRadius="medium" sxPadding="medium">
        <div sxCol sxGap="5px">
          <p sxMargin="0" sxTextSize="small" sxColor="two">Remark</p>
          <p sxMargin="0">{{apiAccessRemark.remark}}</p>
        </div>
        <p sxMargin="0" sxTextSize="small" sxColor="two">{{apiAccessRemark.time | date : 'short'}}</p>
      </div>
    </div>
  </div>


  <div sxHeight="40px"></div>

</ion-content>



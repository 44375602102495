import {Inject, Injectable} from '@angular/core';
import {AppVms, PlatformPrefs} from '../model/AppVms';
import {AppName, RestService} from "@qid/common";
import {DisplayService, ValidationUtil} from '@qid/core';
import {AlertController, Platform} from '@ionic/angular';
import {ReplaySubject} from "rxjs";
import {AlertButton} from "@ionic/core/dist/types/components/alert/alert-interface";
import {Browser} from "@capacitor/browser";
import {Capacitor} from "@capacitor/core";
import {App} from "@capacitor/app";
import {Environment, ENVIRONMENT} from "@qid/env";


@Injectable()
export class AppVmsService {


  public static APP_BUILD;
  public static APP_VERSION;

  private static readonly NodeVMS = 'app-vms';

  public vmsObservable: ReplaySubject<AppVms> = new ReplaySubject<AppVms>();
  public vms: AppVms;


  constructor(
    private restService: RestService,
    private displayService: DisplayService,
    private alertController: AlertController,
    private platform: Platform,
    @Inject(ENVIRONMENT) private environment: Environment
  ) {

  }

  public async getVMS() {

    await this.setAppVersion();

    return (
      await this.restService.send<AppVms>({
        url: AppVmsService.NodeVMS,
        method: 'GET',
      })
    ).data;
  }


  public async update(appVms: AppVms) {
    return (await this.restService.send<AppVms>({
      url: AppVmsService.NodeVMS,
      method: "POST",
      body: appVms
    })).data
  }


  public async checkEntry(): Promise<boolean> {
    const vms = await this.getVMS();
    if (!vms) {
      return true
    }
    this.vmsObservable.next(vms)
    this.vms = vms

    switch (true) {
      case this.platform.is('ios') :
        return this.doPlatformCheck(vms.iosPrefs);
      case this.platform.is('android') :
        return this.doPlatformCheck(vms.androidPrefs);
      default :
        return this.doPlatformCheck(vms.qrScannerPrefs)
    }

  }


  public getAppVersion() {
    return AppVmsService.APP_VERSION
  }

  private cancelEntry(def: string = '') {
    const message = this.vms.maintenanceMessage ? this.vms.maintenanceMessage : def;
    this.displayService.alert(message);
    return false;
  }

  private doPlatformCheck(prefs: PlatformPrefs) {

    if (!prefs) {
      return true;
    }

    if (ValidationUtil.isTrue(prefs.lockPlatform)) {
      return this.cancelEntry();
    }

    if (!AppVmsService.APP_BUILD) {
      return true
    }

    if (+prefs.minimumVersion > +AppVmsService.APP_BUILD) {
      this.showAppUpdateAlert(true)
      return false
    }

    if (+prefs.latestVersion > +AppVmsService.APP_BUILD) {
      this.showAppUpdateAlert(false)
    }

    return true;

  }


  async showAppUpdateAlert(isUpdateMandatory: boolean) {

    const message = isUpdateMandatory ? "New Update Available! Please update the app." : 'New Update Available!'

    let buttons: AlertButton[] = [
      {
        text: 'Update now',
        handler: () => {
          Browser.open({
            url: 'https://onelink.to/rdzbz9',
            toolbarColor: '#f1f5f5',
            presentationStyle: 'popover',
          });
        }
      }
    ]

    if (!isUpdateMandatory) {
      buttons.push({
        text: 'Later',
        role: 'cancel'
      })
    }

    const alert = await this.alertController.create({
      header: message,
      buttons: buttons
    })

    alert.present()

  }


  async setAppVersion() {

    if (Capacitor.isNativePlatform()) {
      const appInfo = await App.getInfo()
      AppVmsService.APP_BUILD = +appInfo?.build
      AppVmsService.APP_VERSION = appInfo?.version
    } else if (this.environment.appName == AppName.BusinessSuite) {
      AppVmsService.APP_BUILD = 512
      AppVmsService.APP_VERSION = '5.1.2'
    } else if (this.environment.appName == AppName.Master) {
      AppVmsService.APP_BUILD = 503
      AppVmsService.APP_VERSION = '5.0.3'
    } else if (this.environment.appName == AppName.WebGateway) {
      AppVmsService.APP_BUILD = 506
      AppVmsService.APP_VERSION = '5.0.6'
    } else if (this.environment.appName == AppName.ChromeCFormPlugin) {
      AppVmsService.APP_BUILD = 503
      AppVmsService.APP_VERSION = '5.0.3'
    }


  }

}

<div sxCol [class.disabled]="readonly" (click)="onFieldClicked()" sxMargin="15px 0 0">

  <div *ngIf="label && selectedInputType !== 'time'" sxPadding="0 0 4px 12px" sxRow sxDistribution="start"
       sxAlign="center" sxGap="5px"
       [class.rejectInput]="readonly || fieldCallback">
    <p sxMargin="0" sxTextSize="small" sxColor="three" sxCapitalize="">{{ label }} <span *ngIf="required"
                                                                                         sxColor="danger">*</span></p>
  </div>

  <div sxRow sxDistribution="spaceBetween" sxTextSize="medium" sxAlign="center" sxGap="5px"
       [class.rejectInput]="readonly || fieldCallback" sxBorderColor="one" sxBorderWidth="small"
       [sxPadding]="type === 'dropdown' ? '0 medium': 'medium'" sxBackground="light" sxRadius="small" class="shadow"
       sxMinHeight="58px" [class.focused]="isFocused">

    <ion-icon *ngIf="iconStart" [name]="iconStart" sxColor="two" sxTextSize="22px" sxFlexShrink="0"></ion-icon>

    <div *ngIf="isShowCountrySelector()" (click)="openCountryCodeSelector()" sxRow sxAlign="center"
         sxDistribution="center" sxGap="5px" sxBorderWidth="0 small 0 0" sxBorderColor="one" sxPadding="0 small 0 0">
      {{ countryCode }}
      <ion-icon name="chevron-down-outline"></ion-icon>
    </div>


    <input class="input-field" type="tel" *ngIf="selectedInputType == 'phone'" [(ngModel)]="phoneInput"
           [readonly]="readonly" [placeholder]="placeholder ? placeholder : ''" (ngModelChange)="onPhoneInputChange()">

    <input class="input-field" #inputField [readonly]="readonly" *ngIf="selectedInputType == 'input'"
           [(ngModel)]="value" (focusin)="onFocusChanges(true)" (focusout)="onFocusChanges(false)"
           (ngModelChange)="onValueChange()" (change)="doValidation()" [placeholder]="placeholder ? placeholder : ''"
           [type]="type">


    <div *ngIf="selectedInputType == 'time'" sxRow sxWidth="full" sxDistribution="spaceBetween" sxAlign="center">
      <div *ngIf="label" sxRow sxDistribution="start" sxAlign="center" sxGap="5px"
           [class.rejectInput]="readonly || fieldCallback">
        <p sxMargin="0" sxTextSize="medium" sxColor="two" sxCapitalize="">{{ label }} <span *ngIf="required"
                                                                                              sxColor="danger">*</span>
        </p>
      </div>
      <input (ngModelChange)="onValueChange($event)"
             (change)="doValidation()"
             sxBorderColor="rgba(0,0,0,0.30)"
             sxBorderWidth="1px"
             sxRadius="5px"
             [(ngModel)]="value" type="time">
    </div>

    <textarea [readonly]="readonly" *ngIf="selectedInputType == 'textarea'" sxWidth="fill" sxPadding="0" sxMargin="0"
              [(ngModel)]="value" sxHeight="60px" (focusin)="onFocusChanges(true)" (focusout)="onFocusChanges(false)"
              (ngModelChange)="onValueChange()" (change)="doValidation()" [placeholder]="placeholder ? placeholder : ''"
              sxBorderColor="transparent" sxBackground="transparent"></textarea>

    <div *ngIf="isShowDropDown() && getOptions()?.length > 5" sxWidth="fill" sxCursor="pointer"
         (click)='openSuperSelect()' sxRow sxDistribution='spaceBetween' sxAlign='center'>
      <p sxColor='two' sxMargin='0' *ngIf='!value'>{{ placeholder ? placeholder : "Select" }}</p>
      <p sxMargin='0' sxCapitalize=''>{{ getDropdownSelection(value) }}</p>
      <ion-icon name='caret-down-outline' [style.font-size]="'13px'" sxColor='two'></ion-icon>
    </div>

    <ion-radio-group sxWidth="full" *ngIf="type === 'dropdown' && checkBoxRequired" [(ngModel)]="value"
                     (ngModelChange)="onValueChange()" (ionChange)="doValidation()">
      <ion-list>
        <ion-item *ngFor="let option of getOptions()">
          <ion-label>{{ getLabel(option) }}</ion-label>
          <ion-radio slot="start" [value]="option"></ion-radio>
        </ion-item>
      </ion-list>
    </ion-radio-group>

    <ion-select sxWidth="full" label-placement="stacked" *ngIf="isShowDropDown() && getOptions()?.length <= 5"
                [(ngModel)]="value" [title]="label" interface="popover" mode="md"
                (ngModelChange)="onValueChange()" (ionChange)="doValidation()"
                placeholder="{{placeholder}}">
      <ion-select-option *ngFor="let option of getOptions()" [value]="option">{{ getLabel(option) }}</ion-select-option>
    </ion-select>


  </div>

  <div *ngIf="errorNote || helpNote " sxGap="5px" sxCol sxPadding="0 12px">
    <p sxTextSize="small" sxColor="danger" sxMargin="0">{{ errorNote }}</p>
    <p sxTextSize="small" sxColor="two" sxMargin="0">{{ helpNote }}</p>
  </div>


</div>

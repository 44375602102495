import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {ViewSharePageRoutingModule} from './view-share-routing.module';
import {ViewSharePage} from './view-share.page';
import {SuperModule} from "@qid/super";
import {SexyModule} from "@qid/sexy";
import {IdComponentsModule} from '../../../id';
import {OrganisationModule} from '../../../organisation';
import {OrganisationUserModule, PermissionDirective} from '../../../organisation-user';
import {HideForPlatformDirective} from '../../../../directives/hide-for-platform.directive';
import {RoleAccessDirective} from '../../../../directives/role-access.directive';
import {CollectionTemplateComponentModule} from '../../../collection-template';
import {MasterTemplateModule} from '../../../master-template';
import {FormatPhonePipe} from "../../../../pipes/format-phone.pipe";
import {ShareComponentsModule} from "../../components/share-components.module";


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ViewSharePageRoutingModule,
    SuperModule,
    SexyModule,
    IdComponentsModule,
    OrganisationModule,
    OrganisationUserModule,
    HideForPlatformDirective,
    RoleAccessDirective,
    CollectionTemplateComponentModule,
    MasterTemplateModule,
    PermissionDirective,
    FormatPhonePipe,
    ShareComponentsModule
  ],
  declarations: [ViewSharePage],
})
export class ViewSharePageModule {
}

import {Injectable} from '@angular/core';
import {String} from 'typescript-string-operations';
import {ImageUtil} from '@qid/core';
import {SID} from '../models/SID';
import {CreateSidDto} from '../dto/create-sid.dto';
import {RestService} from "../../../services/rest.service";


@Injectable({
  providedIn: 'root',
})
export class SidService {
  static readonly nodeSid: string = 'sid/';
  static readonly nodeSidId: string = 'sid/{0}';
  static readonly NodeLogo: string = SidService.nodeSid + 'logo/{0}';

  constructor(private restService: RestService) {
  }

  public async readOne(id: string): Promise<SID> {
    return (
      await this.restService.send<SID>({
        url: String.format(SidService.nodeSidId, id),
        method: 'GET',
      })
    ).data;
  }

  async readAll() {
    return (await this.restService.send<SID[]>({
        url: SidService.nodeSid,
        method: 'GET'
      }
    )).data;
  }

  async readAllOrg(account_id: string) {
    return (
      await this.restService.send<SID[]>({
        url: SidService.nodeSid + 'account/' + account_id,
        method: 'GET',
      })
    ).data;
  }

  async create(createSidDto: CreateSidDto) {
    return this.restService.send<SID>({
      url: SidService.nodeSid,
      method: 'POST',
      body: createSidDto,
    });
  }

  async update(sid: SID) {
    return this.restService.send<SID>({
      url: String.format(SidService.nodeSidId, sid._id),
      method: 'PUT',
      body: sid,
    });
  }

  async updateSidLogo(imageFile, sidId: string) {

    const formData = await ImageUtil.getCompressedFormData([imageFile]);

    return this.restService.sendFiles<SID>({
      url: String.format(SidService.NodeLogo, sidId),
      body: formData,
      method: "POST"
    })

  }

  async delete(_id: string) {
    return this.restService.send({
      url: SidService.nodeSid + _id,
      method: 'DELETE',
    });
  }
}

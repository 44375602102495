<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-title>Group Account</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>


  <div sxPadding="medium" sxCol sxGap="10px">

    <div sxPadding="medium" sxRow sxAlign="center" sxGap="10px" sxRadius="medium" sxBorderColor="one" sxBorderWidth="1px">
      <avatar-round [size]="50" [imageUrl]="orgRelationship?.childOrg?.displayPictureUrl"></avatar-round>
      <p>{{orgRelationship?.childOrg?.name}}</p>
    </div>

    <div sxBackground="light" sxRadius="medium" sxPadding="10px 0">
      <ion-item>
        <ion-toggle [(ngModel)]="orgRelationship.shareAllTemplates">Share All Forms</ion-toggle>
      </ion-item>
    </div>


    <super-button [action]="removeRelation" color="danger" background="light">Remove Branch</super-button>

  </div>


</ion-content>

import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import * as AuthActions from '../+state/auth.actions'
import {AccountType, AuthenticationServiceInterface, ValidationUtil} from "@qid/core";
import {firstValueFrom} from "rxjs";
import {Preferences} from "@capacitor/preferences";
import {AuthenticationApiService, AuthSelectors} from "../index";
import {RestService} from "../../../services/rest.service";
import {OtpUtil} from "../../otp/utils/otp.util";
import {HttpClient} from "@angular/common/http";
import {ModalController} from "@ionic/angular";
import {AuthenticationUtil} from "../utils/authentication.util";
import {CreateUserDto, OtpChannel, OtpVerifier} from "../../user";

@Injectable()
export class AuthenticationService implements AuthenticationServiceInterface {

  public static storageKeyAccessToken = 'accessToken'
  public static storageKeyRefreshToken = 'refreshToken'
  public static storageKeySession_id = 'session_id'

  public static isRefreshingToken: boolean = false;

  constructor(
    private store: Store,
    private apiService: AuthenticationApiService,
    private httpClient: HttpClient,
    private modalController: ModalController
  ) {

  }


  async checkPreviousAuthenticatedUser(): Promise<string> {

    const {accessToken, refreshToken, session_id} = await AuthenticationUtil.getLocalAuthState();

    if (!ValidationUtil.isStringEmpty(accessToken) || !ValidationUtil.isStringEmpty(refreshToken) || !ValidationUtil.isStringEmpty(session_id)) {
      await this.signInWithToken(accessToken, refreshToken, session_id)
      return accessToken
    }

    return null

  }

  async signInAnonymously(): Promise<void> {
    return this.apiService.signInAnonymously();
  }

  async signInWithEmailAndPassword(email: string, password: string) {
    // const response: any = await signInWithEmailAndPassword(
    //     this.auth,
    //     email,
    //     password
    // );
    // const token = await this.getCurrentUserToken();
    // this.store.dispatch(AuthActions.fetchAuthTokenSuccess({token: token}))
  }

  async signInWithOtp(receiverId: string, channel: OtpChannel) {

    let phoneVerifier: OtpVerifier = new OtpVerifier(channel);

    phoneVerifier.receiverId = receiverId;

    let accessToken = null;
    let refreshToken = null;
    let session_id = null;

    await this.signInAnonymouslyIfNoUser();

    const verifyRes = await OtpUtil.sendAndVerify(phoneVerifier,
      this.httpClient, this.modalController,
      async (otpVerifier: OtpVerifier, otpInput: string) => {
        const res = await this.apiService.signInWithOtp(otpVerifier.otpId, otpInput)
        if(res.errorMessage){
          throw new Error(res.errorMessage)
        }
        if (res?.data) {
          accessToken = res.data.accessToken;
          refreshToken = res.data.refreshToken
          session_id = res.data.session_id
          await this.signInWithToken(accessToken, refreshToken, session_id)
          return true
        }
        return false;

      });

    return verifyRes?.isVerified

  }

  async signInWithToken(accessToken: string, refreshToken: string, session_id: string) {
    await Preferences.set({key: AuthenticationService.storageKeyAccessToken, value: accessToken})
    await Preferences.set({key: AuthenticationService.storageKeyRefreshToken, value: refreshToken})
    await Preferences.set({key: AuthenticationService.storageKeySession_id, value: session_id})
    RestService.setAuthToken(accessToken)
    this.store.dispatch(AuthActions.fetchAuthTokenSuccess({token: accessToken}))
  }

  async signOut() {

    await Preferences.clear()
    RestService.setAuthToken(null)
    this.store.dispatch(AuthActions.signOut())
  }


  async signInAnonymouslyIfNoUser() {
    const token = await firstValueFrom(this.store.select(AuthSelectors.selectAuthToken));
    if (!token) {
      return this.signInAnonymously()
    }
  }

  public async setActiveEntityAsUser(user) {
    return this.store.dispatch(AuthActions.setActiveAccount({
      account: user, accountType: AccountType.user
    }))
  }

  public async setActiveEntity(organisation, authenticatedUserSelector) {

    if (organisation) {
      return this.store.dispatch(AuthActions.setActiveAccount({
        account: organisation, accountType: AccountType.organisation
      }))
    }

    const user = await firstValueFrom(this.store.select(authenticatedUserSelector))
    return this.store.dispatch(AuthActions.setActiveAccount({
      account: user, accountType: AccountType.user
    }))

  }


  public async refreshToken() {

    if (AuthenticationService.isRefreshingToken) {
      return new Promise(resolve => {
        const interval = setInterval(() => {
          if (!AuthenticationService.isRefreshingToken) {
            clearInterval(interval)
            return resolve(true)
          }
        }, 100)
      })
    }

    AuthenticationService.isRefreshingToken = true;
    const refreshToken = await Preferences.get({key: AuthenticationService.storageKeyRefreshToken})
    const session_id = await Preferences.get({key: AuthenticationService.storageKeySession_id})
    if (!refreshToken?.value || !session_id?.value) {
      return this.signOut()
    }

    const res = await this.apiService.refreshToken(session_id.value, refreshToken.value)

    if(res.errorMessage){
      return this.signOut()
    }

    if (res && res.data.accessToken) {
      await Preferences.set({key: AuthenticationService.storageKeyAccessToken, value: res.data.accessToken})
      await Preferences.set({key: AuthenticationService.storageKeyRefreshToken, value: res.data.refreshToken})
      await Preferences.set({key: AuthenticationService.storageKeySession_id, value: session_id.value})
      RestService.setAuthToken(res.data.accessToken)
    }

    AuthenticationService.isRefreshingToken = false

  }

  async quickSignUp(createUserDto: CreateUserDto) {
    const res = await this.apiService.quickSignUp(createUserDto)
    if (res && res.data && res.data?.refreshToken && res.data?.accessToken && res?.data?.session_id) {
      await this.signInWithToken(res.data.accessToken, res.data.refreshToken, res.data.session_id)
      return true
    }
    if(res.errorMessage){
      throw new Error(res.errorMessage)
    }
    return false
  }


  async signUpWithOtp(
    emailVerifier: OtpVerifier,
    phoneVerifier: OtpVerifier,
    fullName: string
  ) {
    const res = await this.apiService.signUpWithOtp(emailVerifier, phoneVerifier, fullName)

    if(res.errorMessage){
      throw new Error(res.errorMessage)
    }

    if(res && res.data.accessToken && res.data.refreshToken && res.data.session_id){
      return this.signInWithToken(res.data.accessToken, res.data.refreshToken, res.data.session_id)
    }


  }


}

<div
  sxCol
  sxHeight="full"
  sxWidth="full"
  sxDistribution="center"
  sxAlign="center"
  sxPadding="large"
>
  <div sxPadding="20px 5px" sxCol sxAlign="stretch" sxWidth="full">
    <p sxMargin="0" sxTextSize="22px">
      {{scanner?.commonTitle ? scanner?.commonTitle : collectionTemplate?.purpose}}
    </p>
    <h1
      sxLineHeight="60px"
      sxTextSize="60px"
      sxTextWeight="bold"
      sxTextAlign="left"
      id="qid-text"
    >
      It will be<br/>
      quick<br/>
      with <span sxTextStyle="italic">qid.</span>
    </h1>
  </div>

  <div sxHeight="50px"></div>

  <div sxCol sxWidth="full" sxAlign="stretch" sxGap="10px"
       *ngIf="!collectionTemplate && scanner?.scannerTemplateOptions">
    <super-button
      type="light"
      iconEnd="chevron-forward"
      textAlign="start"
      *ngFor="let option of scanner?.scannerTemplateOptions"
      (click)="submit(option.template)"
    >{{option.template.purpose}}</super-button
    >
  </div>
  <super-button
    *ngIf="collectionTemplate"
    sxWidth="full"
    (click)="submit(collectionTemplate)"
    iconEnd="chevron-forward"
  >Start
  </super-button
  >
</div>

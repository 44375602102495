import {Camera} from "@mediapipe/camera_utils";
import {FaceMesh} from "@mediapipe/face_mesh";

export class MediaPipeUtil {

    public static getFaceDistance(landmarks) {

        const chin = landmarks[152];
        const forehead = landmarks[10];

        const faceHeight = Math.sqrt(
            Math.pow(forehead[0] - chin[0], 2) +
            Math.pow(forehead[1] - chin[1], 2)
        );

        return faceHeight ? +(faceHeight * 10).toFixed(1) : null
    }

    public static getFaceRotation(landmarks) {
        // Extract important landmarks
        const leftEye = landmarks[33];
        const rightEye = landmarks[263];
        const noseTip = landmarks[1];
        const chin = landmarks[199];

        if (!leftEye || !rightEye || !noseTip || !chin) return

        const eyeCenterX = (leftEye[0] + rightEye[0]) / 2;
        const yaw = noseTip[0] - eyeCenterX;
        const eyeCenterY = (leftEye[1] + rightEye[1]) / 2;
        const pitch = noseTip[1] - eyeCenterY;

        return [pitch * 100, yaw * 1000];
    }

    public static getVisibilityChangeEventName(): string {
        if (typeof document.hidden !== 'undefined') {
            return 'visibilitychange';
        } else if (typeof (document as any).msHidden !== 'undefined') {
            return 'msvisibilitychange';
        } else if (typeof (document as any).webkitHidden !== 'undefined') {
            return 'webkitvisibilitychange';
        } else {
            throw new Error('Page Visibility API is not supported.');
        }
    }

    public static handleVisibilityChange(camera: Camera): void {
        if (document.hidden) {
            camera.stop();
        } else {
            camera.start();
        }
    }

    public static startCamera(faceMesh: FaceMesh, videoElement) {

        const camera = new Camera(videoElement, {
            onFrame: async () => {
                await faceMesh.send({image: videoElement});
            },
            width: 300,
            height: 300,
        });

        camera.start();

        document.addEventListener(
            MediaPipeUtil.getVisibilityChangeEventName(),
            () => MediaPipeUtil.handleVisibilityChange(camera),
            false
        );
        return camera
    }

}

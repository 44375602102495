import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {GatewayPage} from './gateway-page.component';
import {SexyModule} from "@qid/sexy";
import {SuperModule} from "@qid/super";
import {ShareComponentsModule} from "@qid/common";
import {GatewayPageRoutingModule} from "./gateway-routing.module";
import {GatewayComponentsModule} from "../../components/gateway-components.module";
import {AuthenticationModule, UserComponentsModule} from "@qid/common";

@NgModule({
  declarations: [GatewayPage],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    GatewayPageRoutingModule,
    UserComponentsModule,
    SexyModule,
    SuperModule,
    ShareComponentsModule,
    AuthenticationModule,
    GatewayComponentsModule

  ],

})
export class GatewayPageModule {
}

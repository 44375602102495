<div
        sxPadding="20px 0"
        sxHeight="full"
        sxWidth="full"
>
  <quick-signup
          sxHeight="full"
          sxWidth="full"
          (onLogin)="onLogin($event)"
  ></quick-signup>
</div>

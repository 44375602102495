import {Component, Input, OnInit, ViewChild} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {
  CollectionTemplate,
  Scanner,
  ScanService,
  Share,
  SharesDataRepository,
  ShareService,
  User,
  UsersSelectors,
  VerifiedSignupComponent
} from "@qid/common";
import {Scan, ValidationUtil} from "@qid/core";
import {GatewayAuthenticationComponent} from "../../components/gateway-authentication/gateway-authentication.component";
import {GatewayWelcomeComponent} from "../../components/gateway-welcome/gateway-welcome.component";
import {
  GatewayDocumentSubmissionComponent
} from "../../components/gateway-document-submission/gateway-document-submission.component";
import {firstValueFrom} from "rxjs";
import {GatewayFormComponent} from "../../components/gateway-form/gateway-form.component";
import {GatewaySignatureComponent} from "../../components/gateway-signature/gateway-signature.component";
import {GatewayPoliciesComponent} from "../../components/gateway-policies/gateway-policies.component";
import {
  GatewayContinuePreviousComponent
} from "../../components/gateway-continue-previous/gateway-continue-previous.component";
import {GatewaySuccessfulComponent} from "../../components/gateway-successful/gateway-successful.component";
import {Store} from "@ngrx/store";
import {IonContent, ModalController} from "@ionic/angular";

@Component({
  selector: 'gateway',
  templateUrl: "./gateway-page.component.html",
  styleUrls: ["./gateway-page.component.scss"]
})
export class GatewayPage implements OnInit {

  @ViewChild('gatewayWelcomeComponent') gatewayWelcomeComponent: GatewayWelcomeComponent;
  @ViewChild('gatewayAuthenticationComponent') gatewayAuthenticationComponent: GatewayAuthenticationComponent;
  @ViewChild('gatewayIdSubmissionComponent') gatewayIdSubmissionComponent: GatewayDocumentSubmissionComponent;
  @ViewChild('gatewayFormComponent') gatewayFormComponent: GatewayFormComponent;
  @ViewChild('gatewaySignatureComponent') gatewaySignatureComponent: GatewaySignatureComponent;
  @ViewChild('gatewayPoliciesComponent') gatewayPoliciesComponent: GatewayPoliciesComponent;
  @ViewChild('gatewayContinuePreviousComponent') gatewayContinuePreviousComponent: GatewayContinuePreviousComponent;
  @ViewChild('gatewaySuccessfulComponent') gatewaySuccessfulComponent: GatewaySuccessfulComponent;
  @ViewChild('verifiedSignUp') verifiedSignUp: VerifiedSignupComponent;
  @ViewChild('content') content: IonContent;

  @Input() inAppQrCode: string = null;
  public qrCode: string = null;
  public template: CollectionTemplate = null;
  public urlInvalid: boolean = false;
  public scan: Scan = null;
  public senderUser: User = null;
  public scanner: Scanner;
  public share: Share = new Share();


  public slides = {
    collection: "collection",
    selfCollect: "selfCollect",
    loading: "loading", group: "group",
    welcome: "welcome",
    authentication: "authentication",
    selfie: "selfie",
    idSubmission: "idSubmission",
    form: "form",
    signature: "signature",
    policy: "policy",
    successful: "successful",
    previousShare: "previousShare"
  };

  sequence = ["welcome", "authentication",
    this.slides.previousShare, "idSubmission", "form", "policy", this.slides.selfie, "signature",
    this.slides.successful
  ]

  public gatewayFeatures = {}
  public activeSlide: any = this.slides.welcome;


  constructor(
    private activatedRoute: ActivatedRoute,
    private scanService: ScanService,
    private shareRepository: SharesDataRepository,
    private store: Store,
    private modalController: ModalController,
    private shareService: ShareService
  ) {
  }

  ngOnInit() {

    this.gatewayFeatures[this.slides.welcome] = {
      start: async () => {
        this.goToSlide(this.slides.welcome)
        const template: CollectionTemplate = await firstValueFrom(this.gatewayWelcomeComponent.onSubmit)
        if (template) {
          this.template = template;
        }
        return;
      }
    }

    this.gatewayFeatures[this.slides.authentication] = {
      start: async () => {
        if (this.inAppQrCode) {
          this.senderUser = await firstValueFrom(this.store.select(UsersSelectors.selectAuthenticatedUser))
          return;
        }
        this.goToSlide(this.slides.authentication)
        this.senderUser = await firstValueFrom(this.gatewayAuthenticationComponent.onSubmit)
      }
    }
    this.gatewayFeatures[this.slides.previousShare] = {
      start: async () => {
        this.goToSlide(this.slides.previousShare)
        const newShare = await this.gatewayContinuePreviousComponent.initialize(this.template._id, this.scanner);
        if (newShare) {
          this.share = newShare;
          return;
        }
        this.share = await firstValueFrom(this.gatewayContinuePreviousComponent.onSubmit)
      }
    }

    this.gatewayFeatures[this.slides.idSubmission] = {
      start: async () => {
        this.goToSlide(this.slides.idSubmission)
        await firstValueFrom(this.gatewayIdSubmissionComponent.onSubmit)
        return;
      }
    }


    this.gatewayFeatures[this.slides.selfie] = {
      start: async () => {
        if (!this.template.isSelfieVerification) {
          return;
        }
        this.goToSlide(this.slides.selfie)
        this.verifiedSignUp.init()
        await firstValueFrom(this.verifiedSignUp.onSubmit)
      }
    }

    this.gatewayFeatures[this.slides.form] = {
      start: async () => {
        if (!this.template.otherInfo || this.template.otherInfo.length < 1) {
          return;
        }
        this.goToSlide(this.slides.form)
        this.gatewayFormComponent.init();
        return firstValueFrom(this.gatewayFormComponent.onSubmit)
      }
    }

    this.gatewayFeatures[this.slides.signature] = {
      start: async () => {
        if (!this.template.isSignature) {
          return;
        }
        this.goToSlide(this.slides.signature)
        await this.gatewaySignatureComponent.init();
        await firstValueFrom(this.gatewaySignatureComponent.onSubmit)
      }
    }

    this.gatewayFeatures[this.slides.policy] = {
      start: async () => {
        if (!this.template.agreements || this.template.agreements.length < 1) {
          return;
        }
        this.goToSlide(this.slides.policy)
        return firstValueFrom(this.gatewayPoliciesComponent.onSubmit)
      }
    }

    this.gatewayFeatures[this.slides.successful] = {
      start: async () => {
        this.goToSlide(this.slides.successful)
        const share = await this.gatewaySuccessfulComponent.init()
        if (share && this.inAppQrCode) {
          await this.shareRepository.refresh()
        }
      }
    }

  }

  ionViewDidEnter() {
    if (ValidationUtil.isStringEmpty(this.inAppQrCode)) {
      this.fetchRouterData();
      return
    }

    this.qrCode = this.inAppQrCode
    this.fetchScan(this.qrCode)

  }

  async fetchRouterData() {

    this.activatedRoute.params.subscribe(async (data) => {
      if (data && data["qr"]) {
        this.qrCode = data["qr"];
        await this.fetchScan(data["qr"]);
        return;
      }
      this.urlInvalid = true;
    });
  }

  async fetchScan(qrId: string) {

    const response = await this.scanService.scan(qrId);
    if (!response) {
      this.urlInvalid = true;
      return;
    }
    this.scanner = response.scanner;
    ScanService.Scan = response.scan;
    this.startSubmission();

  }


  async startSubmission() {

    for (let step of this.sequence) {
      if (!this.gatewayFeatures[step]) {
        continue
      }
      await this.gatewayFeatures[step].start();
    }

  }


  goToSlide(slide) {
    this.activeSlide = slide;
    this.content.scrollToTop();
  }

  reload() {
    location.reload();
  }

  public closeModal() {
    this.modalController.dismiss()
  }


}

import {Injectable} from "@angular/core";
import {CreateOrganisationRelationshipDto} from "../dto/create-organisation-relationship.dto";
import {Store} from "@ngrx/store";
import {RestService} from "../../../services/rest.service";
import {Organisation} from "../../organisation";
import {OrganisationRelationship} from "../model/organisation-relationship.model";
import {CollectionTemplate} from "../../collection-template";


@Injectable()
export class OrganisationRelationshipService {


  private static readonly ApiEndpoint = "organisation-relationship/";


  constructor(
    private restService: RestService,
    private store: Store
  ) {

  }


  read() {

  }

  create(createOrganisationRelationDto: CreateOrganisationRelationshipDto) {
    return this.restService.send<Organisation[]>({
      url: OrganisationRelationshipService.ApiEndpoint,
      method: "POST",
      body: createOrganisationRelationDto
    })
  }

  getParent() {
    return this.restService.send<OrganisationRelationship[]>({
      url: OrganisationRelationshipService.ApiEndpoint + 'parent',
      method: "GET"
    })
  }

  getChildren(parentOrgId: string) {
    return this.restService.send<OrganisationRelationship[]>({
      url: OrganisationRelationshipService.ApiEndpoint + 'children',
      method: "GET",
      query: {
        parentOrgId: parentOrgId
      }
    })
  }

  async getParentTemplates(childOrgId: string) {
    return this.restService.send<CollectionTemplate[]>({
      url: OrganisationRelationshipService.ApiEndpoint + 'templates',
      method: "GET",
      query: {
        childOrgId: childOrgId
      }
    })
  }

  delete(relation_id : string){
    return this.restService.send<OrganisationRelationship>({
      url: OrganisationRelationshipService.ApiEndpoint + relation_id,
      method: "DELETE",
    })
  }
}

<div sxCol sxGap="10px" sxPadding="medium" sxBackground="light" sxRadius="medium">

    <div *ngIf="authenticatedUser$ | async as user" sxRow sxGap="10px" sxAlign="center" sxDistribution="spaceBetween"
         sxBorderColor="one" sxBorderWidth="0 0 1px">
        <div sxRow sxGap="10px" sxAlign="center">
            <avatar-round [imageUrl]="participant?.account?.displayPictureUrl" [title]="participant?.account?.name"
                          [size]="30"></avatar-round>
            <p sxCapitalize>{{participant?.account?.name?.toLowerCase()}}<span sxColor="two" sxTextSize="small"
                                                   sxMargin="0 5px">{{participant.account_id == user._id && template.allowGroup ? '(You)' : ""}}</span>
            </p>
        </div>
        <div sxRow sxAlign="center" *ngIf="deleteButton">
            <super-button padding="0" height="fit" type="clear" [action]="removeParticipant" confirmationTitle="Remove">
                <ion-icon *ngIf="participant.account_id != user._id" name="close-circle-outline"
                          sxTextSize="30px"></ion-icon>
            </super-button>

        </div>
    </div>

    <h3 *ngIf="participant.mandatoryIds?.length > 0" sxMargin="5px 10px 0" sxTextSize="small" sxTextAlign="left"
        sxColor="two">
        Required Documents
    </h3>

    <id-uploader
            *ngFor="let field of participant.mandatoryIds"
            [field]="field"
            [account]="participant?.account"
            (onIdUpload)="onIdUpload()"
    ></id-uploader>

    <h3 sxMargin="5px 10px 0" sxTextSize="small" sxTextAlign="left" sxColor="two">
        {{participant.optionalLabel}}
    </h3>

    <id-uploader
            *ngFor="let field of participant.optionalIds"
            sxWidth="fill"
            [field]="field"
            [account]="participant?.account"
            (onIdUpload)="onIdUpload()"
    ></id-uploader>
</div>

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SuperButtonComponent} from "./components/super-button/super-button.component";
import {SuperButtonIconComponent} from "./components/super-button-icon/super-button-icon.component";
import {SuperInputComponent} from "./components/super-input/super-input.component";
import {SuperInputModalComponent} from "./components/super-input-modal/super-input-modal.component";
import {SuperSelectComponent} from "./components/super-select/super-select.component";
import {SuperSkeletonComponent} from "./components/super-skeleton/super-skeleton.component";
import {SuperStackComponent} from "./components/super-stack/super-stack.component";
import {SuperStepItemComponent} from "./components/super-step-item/super-step-item.component";
import {FormsModule} from "@angular/forms";
import {IonicModule} from "@ionic/angular";
import {SexyModule} from "@qid/sexy";
import {PinchToZoomComponent} from "./components/pinch-to-zoom/pinch-to-zoom.component";
import {PageLoaderComponent} from "./components/page-loader/page-loader.component";
import {PaginationComponent} from "./components/pagination/pagination.component";
import {PageActionComponent} from "./components/page-action/page-action.component";
import {UploadImageButtonComponent} from "./components/upload-image-button/upload-image-button.component";
import {AvatarRoundComponent} from "./components/avatar-round/avatar-round.component";
import {PaginationBoxComponent} from './components/pagination-box/pagination-box.component';
import {MockSearchBarComponent} from "./components/mock-search-bar/mock-search-bar.component";
import {SuperDocumentCaptureComponent} from "./components/super-document-capture/super-document-capture.component";
import {AngularCropperjsModule} from 'angular-cropperjs';
import {ImageCropperComponent} from './components/image-croppper/image-cropper.component';
import {SuperTableComponent} from "./components/super-table/super-table.component";
import {SuperRowComponent} from "./components/super-row/super-row.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SexyModule,
    AngularCropperjsModule,
  ],
  declarations: [
    SuperButtonComponent,
    SuperButtonIconComponent,
    SuperInputComponent,
    SuperInputModalComponent,
    SuperSelectComponent,
    SuperSkeletonComponent,
    SuperStackComponent,
    SuperStepItemComponent,
    AvatarRoundComponent,
    UploadImageButtonComponent,
    PageActionComponent,
    PaginationComponent,
    PageLoaderComponent,
    PinchToZoomComponent,
    PaginationBoxComponent,
    MockSearchBarComponent,
    SuperDocumentCaptureComponent,
    ImageCropperComponent,
    SuperTableComponent,
    SuperRowComponent
  ],
  exports: [
    SuperButtonComponent,
    SuperButtonIconComponent,
    SuperInputComponent,
    SuperInputModalComponent,
    SuperSelectComponent,
    SuperSkeletonComponent,
    SuperStackComponent,
    SuperStepItemComponent,
    AvatarRoundComponent,
    UploadImageButtonComponent,
    PageActionComponent,
    PaginationComponent,
    PageLoaderComponent,
    PinchToZoomComponent,
    PaginationBoxComponent,
    MockSearchBarComponent,
    SuperDocumentCaptureComponent,
    ImageCropperComponent,
    SuperTableComponent,
    SuperRowComponent

  ],
})
export class SuperModule {
}

<div sxPadding="medium" sxHeight="full">

  <div sxBackground="light" sxRadius="medium" sxWidth="full">
    <div *ngIf="mainParticipant" sxRow sxGap="10px" sxBorderWidth="0 0 1px 0" sxBorderColor="one"
         sxPadding="medium" sxAlign="center">
      <avatar-round [size]="30" [imageUrl]="mainParticipant?.displayPictureUrl"
                    [title]="mainParticipant?.name"></avatar-round>
      <p sxMargin="0">{{mainParticipant?.name}}</p>
    </div>
    <media-pipe (onCapture)="onCaptured($event)"></media-pipe>
  </div>

</div>

<div id="id-list" sxBackground="light" sxRadius="medium" sxRow
     sxAlign="stretch">
    <div sxWidth="fill" sxGap="5px" sxCol sxPadding="medium" sxDistribution="center" (click)="viewId()">
        <p sxMargin="0" sxTextSize="16px" sxColor="three" sxTextWeight="bold">{{field?.name}}</p>
        <div *ngIf="selectedDocumentAccess" sxRow sxGap="5px">
            <p sxMargin="0" sxTextSize="small"
               sxColor="two">{{selectedDocumentAccess?.document?.documentNumberMasked || 'xx xx xxxx'}}</p>
        </div>

    </div>
    <div sxRow sxAlign="center" sxPadding="medium" *ngIf="!selectedDocumentAccess">
        <super-button (click)="createId()" color="primary" padding="5px 0" height="fit" type="clear">
            Upload
        </super-button>
    </div>
    <div sxRow sxAlign="center" sxBackground="#D8DEE3" sxPadding="medium"
         *ngIf="selectedDocumentAccess">
        <ion-checkbox [checked]="true" (ionChange)="skipToggle($event)"></ion-checkbox>
    </div>

</div>
<p class="error-message" *ngIf="field.errorMessage">{{field.errorMessage}}</p>

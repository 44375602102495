import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {ModalController} from "@ionic/angular";
import {User} from "firebase/auth";
import {ID} from "../../models/ID";
import {CreateIdPage} from "../../pages/create-id/create-id.page";
import {ViewIdPage} from "../../pages/view-id/view-id.page";
import {DocumentAccess, DocumentAccessService} from "../../../document-access";
import {IdService} from "../../services/id.service";
import {SidService} from "../../../sid";
import {Field} from "@qid/field";
import {IdField} from "../../interface/IdField";
import {DateUtil} from "@qid/core";


@Component({
  selector: "id-uploader",
  templateUrl: "./id-upload.component.html",
  styleUrls: ["./id-upload.component.scss"]
})
export class IdUploadComponent implements OnInit {

  @Input() field: Field<IdField>
  @Input() account: User | any = null;
  @Output() onIdUpload: EventEmitter<any> = new EventEmitter();

  public uploaded: boolean = false;

  public selectedDocumentAccess: DocumentAccess = null;
  public allDocumentAccess: DocumentAccess[] = [];

  constructor(private modalController: ModalController,
              private documentAccessService: DocumentAccessService,
              private documentService: IdService,
              private sidService: SidService
  ) {
  }

  ngOnInit() {
    this.fetchSid()
  }

  async fetchSid() {
    if (!this.field.data?.sidId) {
      return
    }
    this.field.data.sid = await this.sidService.readOne(this.field.data.sidId)
  }

  ngOnChanges() {
    this.matchId();
  }

  async matchId() {

    const res = await this.documentAccessService.findAll(this.field.data.sidId, this.account?._id)
    if (res && res.data) {
      this.allDocumentAccess = res.data;
      this.selectDocumentAccess(res.data[0])
    }

  }

  async createId() {
    const modal = await this.modalController.create({
      component: CreateIdPage,
      componentProps: {
        sidId: this.field.data.sidId,
        account: this.account
      },
      animated: true
    });

    await modal.present();
    const res = await modal.onDidDismiss();
    if (res && res.data.id) {
      await this.checkAfterDocumentCreate();
      this.field.errorMessage = null;
      this.onIdUpload.emit();
    }
  }

  async checkAfterDocumentCreate() {
    let attempts = 0;
    const previousLength = this.allDocumentAccess?.length || 0
    while (attempts < 10) {
      await this.matchId()
      if (this.allDocumentAccess?.length > previousLength) {
        break
      }
      attempts++;
    }
    return true
  }

  skipToggle(event) {
    // if (this.field.data?.documentAccess) {
    //   this.field.data.skip = !event.detail.checked;
    // }
    // this.field.data.skip = !event.detail.checked;
  }

  async viewId() {
    if (!this.selectedDocumentAccess) {
      return;
    }
    const modal = await this.modalController.create({
      component: ViewIdPage,
      componentProps: {
        id: this.selectedDocumentAccess.document,
        documentData: this.selectedDocumentAccess.documentData,
        showEditOptions: false
      },
      animated: false
    });
    await modal.present();
  }

  async openViewIdPage(id: ID) {

    if (!id) {
      return
    }

    const modal = await this.modalController.create({
      component: ViewIdPage,
      componentProps: {
        id: id,
      },
      animated: false,
    });
    modal.present();
  }

  selectDocumentAccess(documentAccess: DocumentAccess) {
    this.selectedDocumentAccess = documentAccess;
    this.field.data.documentAccess = documentAccess;
    this.onIdUpload.emit();
  }

}

<div
  sxRow
  sxDistribution="spaceBetween"
  sxAlign="center"
  sxBorderColor="one"
  sxBorderWidth="0 0 1px 0"
  sxPadding="medium">
  <p sxMargin="0">{{ sid?.name }}</p>
  <div sxRow sxAlign="center" sxGap="10px">
    <super-button
      size="small"
      background="light"
      slot="end"
      color="primary"
      [action]="update"
    >Save
    </super-button>
    <super-button
      size="small"
      background="light"
      slot="end"
      color="danger"
      [action]="delete"
      confirmationTitle="{{'Delete ' + sid?.name}}"
    >Delete
    </super-button>
    <ion-icon
        sxCursor="pointer"
        sxTextSize="30px"
        name="close-outline"
        (click)="closedSID()"
    >
    </ion-icon>
  </div>

</div>

<div sxCol sxPadding="medium" sxGap="10px">
  <div sxRow sxAlign="center" sxGap="25px" sxWidth="full"
  sxPadding="medium 0 medium 10px">
    <avatar-round
            [size]="80"
            [isEditMode]="true"
            [autoBase]="!!sid.logoUrl"
            [imageUrl]="sid.logoUrl ? sid.logoUrl : 'core-assets/imgs/logo-icon.jpg'"
            [onImageAction]="uploadSidLogo"></avatar-round>
    <super-input
            label="ID Name"
            sxWidth="fill"
            [(value)]="sid.name"></super-input>
  </div>
  <super-input label="Issuer"
    [(value)]="sid.issuer"></super-input>
  <ion-list>
    <ion-item>
      <ion-label>Skip Name Check</ion-label>
      <ion-toggle
        [(ngModel)]="sid.canSkipNameCheck"></ion-toggle>
    </ion-item>
    <ion-item>
      <ion-label>Skip Document Number Check</ion-label>
      <ion-toggle
        [(ngModel)]="sid.canSkipIdNumberCheck"></ion-toggle>
    </ion-item>
    <ion-item>
      <ion-label>Document Type ID</ion-label>
      <ion-note slot="end">{{sid._id}}</ion-note>
    </ion-item>
  </ion-list>
</div>

<div *ngIf="sid?.isSystemApproved" sxPadding="medium" sxCol sxGap="10px">
  <super-input
    label="Key Name"
    sxWidth="fill"
    [(value)]="sid.keyName"></super-input>
  <ion-list>
    <ion-item>
      <ion-toggle
        [(ngModel)]="sid.doAutoFillEffort">Autofill</ion-toggle>
    </ion-item>
    <ion-item>
      <ion-toggle
        [(ngModel)]="sid.confirmAutofillFromUser">Confirm Autofill from User</ion-toggle>
    </ion-item>
  </ion-list>

</div>

<div sxCol sxPadding="medium" sxGap="10px">
  <div sxCol sxGap="10px" sxPadding="small">
    <h2 sxColor="three">Photos</h2>
    <p sxMargin="0" sxTextSize="small" sxColor="two">
      Specify the method for the user to upload this ID, for example, Aadhaar
      requires two photos: Front Side and Back Side
    </p>
  </div>
  <div
    *ngFor="let photo of sid?.photos; let i = index"
    sxRadius="medium"
    sxBackground="light">
    <ion-list>
      <ion-item>
        <ion-input
          [(ngModel)]="photo.name"
          label="Photo Name"
          label-placement="stacked"
          placeholder="Eg. Front Side, Back Side"></ion-input>
      </ion-item>
      <ion-item>
        <ion-select label="Face on Photo" label-placement="stacked"
          [(ngModel)]="photo.faceCheck"
          interface="popover">
          <ion-select-option [value]="0">No</ion-select-option>
          <ion-select-option [value]="1">May Be</ion-select-option>
          <ion-select-option [value]="2">Must Be</ion-select-option>
        </ion-select>
      </ion-item>
      <ion-item>
        <ion-input label-placement="stacked" label="Keywords, should be present"
          [(ngModel)]="photo.testKeywords"
          placeholder="Add keywords Separated by '|'"></ion-input>
      </ion-item>

      <ion-item>
        <ion-input label-placement="stacked" label="Keywords, should not be present"
                   [(ngModel)]="photo.testKeywordsForbidden"
                   placeholder="Add keywords Separated by '|'"></ion-input>
      </ion-item>

      <ion-item
        *ngIf="sid.photos.length > 1"
        button=""
        detail-icon="trash-outline"
        (click)="removePhoto(i)">
        <ion-label color="danger">Remove</ion-label>
      </ion-item>
    </ion-list>
  </div>

  <ion-list>
    <ion-item
      (click)="addPhoto()"
      button=""
      detail-icon="none">
      <ion-icon name="add-outline" slot="start"></ion-icon>
      <ion-label>Add Photo Option</ion-label>
    </ion-item>
  </ion-list>
</div>

<div sxCol sxPadding="medium" sxGap="10px">
  <div sxRow sxDistribution="spaceBetween" sxGap="10px" sxPadding="small">
    <h2 sxColor="three">Fields</h2>
    <ion-button fill="clear" size="small" (click)="addField()">Add</ion-button>
  </div>

  <ion-accordion-group>
    <ion-reorder-group sxWidth="full" disabled="false" (ionItemReorder)="handleReorder($any($event))">
      <ion-accordion
        *ngFor="let field of sid.manualFields; let i = index"
        [value]="i"
        sxMargin="10px 0"
        sxRadius="small"
        sxBorderWidth="1px"
        sxBorderColor="one">
        <ion-item slot="header" color="light">
          <ion-input
            [(ngModel)]="field.label"
            placeholder="Field Label"></ion-input>
          <ion-button
            fill="clear"
            size="small"
            slot="end"
            color="dark"
            (click)="removeField(i)"
          >
            <ion-icon name="trash-outline"></ion-icon
            >
          </ion-button>
          <ion-reorder slot="end"></ion-reorder>
        </ion-item>
        <div
          slot="content"
          sxBorderWidth="1px 0"
          sxBorderColor="one"
          sxCol
          sxGap="10px"
          sxPadding="medium">
          <div
            sxRow
            sxDistribution="spaceBetween"
            sxAlign="center"
            sxGap="10px">
            <ion-item
              sxWidth="fill"
              sxBorderColor="one"
              sxBorderWidth="1px"
              sxRadius="small">
              <ion-label position="stacked">Placeholder</ion-label>
              <ion-input [(ngModel)]="field.placeholder"></ion-input>
            </ion-item>
            <ion-item
              sxWidth="fill"
              sxBorderColor="one"
              sxBorderWidth="1px"
              sxRadius="small">
              <ion-label position="stacked">Field ID</ion-label>
              <ion-input [(ngModel)]="field.name"></ion-input>
            </ion-item>

            <ion-item
              sxWidth="fill"
              sxBorderColor="one"
              sxBorderWidth="1px"
              sxRadius="small">
              <ion-label position="stacked">Field Type</ion-label>
              <ion-select [(ngModel)]="field.type">
                <ion-select-option
                  *ngFor="let type of inputTypes"
                  [value]="type"
                >{{ type }}</ion-select-option
                >
              </ion-select>
            </ion-item>
            <ion-item
              sxWidth="fill"
              sxBorderColor="one"
              sxBorderWidth="1px"
              sxRadius="small">
              <ion-label>Mandatory</ion-label>
              <ion-checkbox [(ngModel)]="field.isMandatory"></ion-checkbox>
            </ion-item>
          </div>
          <ion-item
            *ngIf="field.type === 'dropdown'"
            sxBorderWidth="1px"
            sxRadius="small"
            sxBorderColor="one">
            <ion-label position="stacked">Dropdown Options</ion-label>
            <ion-textarea
              placeholder="Comma Separated"
              [(ngModel)]="field.options"></ion-textarea>
          </ion-item>
        </div>
      </ion-accordion>
    </ion-reorder-group>
  </ion-accordion-group>
</div>

<div class="spacer"></div>

import {Injectable} from "@angular/core";
import {Share} from "../model/Share";
import {CreateShareDto} from "../dto/create-share.dto";
import {ReadShareQuery} from "../model/ReadShareQuery";
import {UpdateShareDto} from "../dto/update-share.dto";
import {Store} from "@ngrx/store";
import {firstValueFrom} from "rxjs";
import {AccountType} from "@qid/core";
import {CreateShareForUnregisteredDto} from "../dto/create-share-for-unregistered.dto";
import {RestService} from "../../../services/rest.service";
import {RestResponse} from "../../../interface/RestInterface";
import {AuthSelectors} from "../../authentication";

@Injectable()
export class ShareService {
  static readonly ApiEndpoint = "share/";

  constructor(
    private restService: RestService,
    private store: Store
  ) {
  }

  public async createShare(
    createShareDto: CreateShareDto
  ): Promise<Share> {

    return (
      await this.restService.send<Share>({
        url: ShareService.ApiEndpoint,
        body: createShareDto,
        method: "POST"
      })
    ).data;
  }

  public async createShareForUnregistered(
    createShareForUnregisteredDto: CreateShareForUnregisteredDto
  ): Promise<RestResponse<Share>> {
    return this.restService.send<Share>({
      url: ShareService.ApiEndpoint + 'create-for-unregistered',
      body: createShareForUnregisteredDto,
      method: "POST"
    })
  }

  async read(id: string) {
    return (await this.restService.send<Share>({
      url: ShareService.ApiEndpoint + id,
      method: "GET"
    })).data;
  }

  async readAll(query: ReadShareQuery, account_id: string, accountType: AccountType) {

    return (
      await this.restService.send<any>({
        url: ShareService.ApiEndpoint,
        method: 'GET',
        query: query,
        account_id: account_id,
        accountType: accountType
      })
    ).data;
  }

  async update(id: string, updateDto: UpdateShareDto) {
    return this.restService.send<Share>({
      url: ShareService.ApiEndpoint + id,
      method: "PUT",
      body: updateDto
    })
  }

  async updateAsOrganiser(id: string, updateDto: UpdateShareDto) {
    return this.restService.send<Share>({
      url: ShareService.ApiEndpoint + 'organiser/' + id,
      method: "PUT",
      body: updateDto
    })
  }

  async findMyOpenShareByTemplateId(templateId: string, orgId: string) {
    return (await this.restService.send<Share[]>({
      url: ShareService.ApiEndpoint + 'status-open',
      method: "GET",
      query: {
        templateId: templateId,
        orgId: orgId
      }
    })).data;
  }

  public async delete(shareId: string): Promise<any> {
    const account_id = await firstValueFrom(this.store.select(AuthSelectors.selectActiveAccountId))
    return this.restService.send({
      url: ShareService.ApiEndpoint + shareId,
      method: 'DELETE',
      account_id: account_id,
      accountType: AccountType.organisation
    });
  }

  async unlock(_id): Promise<Share> {
    return (await this.restService.send<Share>({
      url: ShareService.ApiEndpoint + 'unlock/' + _id,
      method: "PUT"
    })).data
  }

}

import {Component, EventEmitter, Input, Output} from '@angular/core';
import { CollectionTemplate, CollectionTemplateAgreement } from '@qid/common';
import { Share, ShareService } from '@qid/common';

@Component({
  selector: 'gateway-policies',
  templateUrl: './gateway-policies.component.html',
  styleUrl: './gateway-policies.component.scss',
})
export class GatewayPoliciesComponent {

  @Input() template: CollectionTemplate;
  @Input() share: Share;
  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();
  public agreements: CollectionTemplateAgreement;

  constructor(public shareService: ShareService) {
  }

  onAgreements = async () => {
    if (!this.validatePolicies()) {
      throw "Please accept required terms & conditions";
    }
    this.share.agreements = this.template.agreements;
    const res = await this.shareService.update(this.share._id, {agreements: this.share.agreements, updateRemark : "Policies Accepted"});
    if(!res || res.errorMessage){
      throw new Error(res?.errorMessage)
    }
    this.onSubmit.emit(this.share);
  };

  private validatePolicies() {
    if (!this.template.agreements || this.template.agreements.length < 1) {
      return true;
    }
    for (let agreement of this.template.agreements) {
      if (agreement.mandatory && !agreement.accepted) {
        return false;
      }
    }
    return true;
  }

}

import {Component, Input, OnInit} from "@angular/core";
import {SocialSharing} from "@awesome-cordova-plugins/social-sharing/ngx";
import {LoadingController, ModalController} from "@ionic/angular";
import {DisplayService, ImageUtil} from "@qid/core";
import {ID} from "../../models/ID";
import {CreateIdPage} from "../create-id/create-id.page";
import {Store} from "@ngrx/store";
import {firstValueFrom, map} from "rxjs";
import {PinchToZoomComponent} from "@qid/super";
import {Capacitor} from "@capacitor/core";
import {ScannedImage} from "../../../ocr";
import {AuthSelectors} from "../../../authentication";
import {CryptoUtil} from "@qid/crypto";
import {EncryptionKeysService} from "../../../encryption-keys";
import {DocumentAccess, DocumentAccessService} from "../../../document-access";
import {DocumentData} from "../../models/document-data.model";

@Component({
  selector: 'app-view-id',
  templateUrl: './view-id.page.html',
  styleUrls: ['./view-id.page.scss'],
})
export class ViewIdPage implements OnInit {

  @Input() id: ID;

  @Input() documentData: DocumentData

  activeAccount$ = this.store.select(AuthSelectors.selectActiveAccount)

  sharedToAccounts = []

  isOwnerView$ = this.activeAccount$.pipe(
    map(account => {
      return account?._id == this.id.account_id
    })
  );

  documentAccess : DocumentAccess = null

  accessRemark : string

  ownerImage = null

  constructor(private modalController: ModalController,
              private socialSharing: SocialSharing,
              private loadingController: LoadingController,
              private store: Store,
              private displayService: DisplayService,
              private encryptionService: EncryptionKeysService,
              private documentAccessService: DocumentAccessService,
  ) {
  }

  ngOnInit() {

  }

  ionViewDidEnter() {
    this.fetchDocumentAccess();
  }

  // async decryptId() {
  //   const activeAccount = await firstValueFrom(this.activeAccount$)
  //   const keys = await this.encryptionService.read(activeAccount._id)
  //
  //   if (this.id && this.id.aesRsaEncryptedData) {
  //     const privateKey = await CryptoUtil.extractPrivateKeyFromPassword("Manu1234@", keys.key, keys.salt)
  //     const data = await CryptoUtil.decryptDataWithRsaAndAes(
  //       this.id.aesRsaEncryptedData.dataCipher,
  //       privateKey,
  //       this.id.aesRsaEncryptedData.rsaEncryptedAesKey,
  //       this.id.aesRsaEncryptedData.ivBase64)
  //     if (data) {
  //       this.id.documentData = JSON.parse(data)
  //     }
  //   }
  // }

  getImage(url: string) {
    return ImageUtil.getImageSource(url);
  }

  close() {
    this.modalController.dismiss({id: this.id});
  }

  async fetchDocumentAccess() {
    try {
      const res = await this.documentAccessService.findByDocumentId(this.id?._id);

      if (!res.data) {
        return this.displayService.toast({
          message: res.errorMessage
        })
      }

      const documentAccesses: DocumentAccess[] = res.data;

      // Use a Set to track unique grantedToAccount_id values
      const addedIds = new Set<string>();

      const activeAccount = await firstValueFrom(this.activeAccount$)


      for (const access of documentAccesses) {

        if (!access.grantedToAccount) {
          continue;
        }

        if (activeAccount?._id == access.grantedToAccount?._id) {
          this.documentData = access.documentData
          this.accessRemark = access.accessRemark
          this.documentAccess = access
        }

        if(access.grantedToAccount?._id == access?.documentOwnerAccount?._id) {
          continue;
        }

        if (!addedIds.has(access.grantedToAccount._id) &&
          access.grantedToAccount?.name) {
          addedIds.add(access.grantedToAccount._id);
          this.sharedToAccounts.push(access.grantedToAccount);
        }
      }

      this.createPhoto()

    } catch (error) {
      console.error('Error fetching document access:', error);
    }
  }

  async createPhoto(){

    if(this.documentAccess?.documentOwnerAccount?.displayPictureUrl?.length > 0){
      this.ownerImage = ImageUtil.getImageSource(this.documentAccess?.documentOwnerAccount?.displayPictureUrl)
      return
    }

    if(this.documentAccess?.document?.sid?.name != 'Aadhaar' && this.documentAccess?.document?.sid?.name != 'Passport'){
      return;
    }

    const landmarks = this.documentAccess?.documentData?.scannedImages[0]?.faceLandmark
    const imageUrl = this.documentAccess?.documentData?.scannedImages[0]?.imageUrl

    let image = await ImageUtil.getImageUrlFromPhotoLandmarks(ImageUtil.getImageSource(imageUrl), landmarks)

    if(image){
      this.ownerImage = image
    }

  }

  async viewPhoto(image: ScannedImage) {
    const modal = await this.modalController.create({
      component: PinchToZoomComponent,
      componentProps: {
        imageUrl: image.imageUrl
      },
      animated: false
    })
    modal.present();
  }

  async updateDocumentClicked() {
    const account = await firstValueFrom(this.activeAccount$)
    const modal = await this.modalController.create({
      component: CreateIdPage,
      componentProps: {
        id: this.id,
        sidId: this.id.sid._id,
        account: {...account}
      },
      animated: true
    })

    await modal.present();
    const res = await modal.onDidDismiss()
    if (res && res.data.id) {
      this.fetchDocumentAccess()
    }
  }

  share = async (image: ScannedImage) => {
    const dataUrl = this.getImage(image.imageUrl);

    if (Capacitor.getPlatform() == 'android') {
      return this.socialSharing.share(this.id?.sid?.name, 'www.oneqid.com', dataUrl);
    }
    if (Capacitor.getPlatform() == 'ios') {
      return this.socialSharing.shareViaWhatsApp(this.id?.sid?.name, dataUrl, "https://oneqid.com");
    }
  }

  shareToLocal = async (image: ScannedImage) => {
    const loader = await this.loadingController.create({
      message: "Downloading"
    })

    loader.present();

    const imageUrl = ImageUtil.getImageSource(image.imageUrl)
    const uri = await ImageUtil.downloadImage(imageUrl);
    loader.dismiss()
    if (uri && uri.length > 0) {
      this.displayService.toast({
        message: "Image Saved"
      })
    }

  }

  async openViewIdPage(id: ID) {

    if (!id) {
      return
    }

    const modal = await this.modalController.create({
      component: ViewIdPage,
      componentProps: {
        id: id,
      },
      animated: false,
    });
    modal.present();
  }


}

import {Component, ElementRef, EventEmitter, Output, ViewChild} from '@angular/core';
import {FaceMesh,} from '@mediapipe/face_mesh';
import {MediaPipeUtil} from "./media-pipe.util";
import {Camera} from '@mediapipe/camera_utils';

@Component({
  selector: 'media-pipe',
  templateUrl: './media-pipe-playground.component.html',
  styleUrls: ['./media-pipe-playground.component.scss'],
})
export class MediaPipePlaygroundComponent {

  @ViewChild('videoElement') videoElement!: ElementRef<HTMLVideoElement>;
  @ViewChild('canvasElement') canvasElement!: ElementRef<HTMLCanvasElement>;
  @ViewChild('cameraResultCanvas') cameraResultCanvas: ElementRef<HTMLCanvasElement>;

  @Output('onCapture') onCapture = new EventEmitter<string>();

  public isFaceDetected: boolean = false;
  public isLeftActive: boolean = false;
  public isRightActive: boolean = false;
  public isTopActive: boolean = false;
  public isBottomActive: boolean = false;
  public canvasCtx!: CanvasRenderingContext2D;
  public distanceMessage: string = null;

  public instruction = null;

  public isFaceRotationScanned = false;
  public countDownNumber = null;
  public countDownInterval = null;
  public isCaptured: boolean = false;

  public camera: Camera
  public cameraCapturedImageData = null;
  public slides = {
    scan: 'scan',
    preview: 'preview',
    instructions: 'instructions'
  }
  public activeSlide = this.slides.instructions

  constructor() {
  }

  ngAfterViewInit(): void {

  }


  private initializeFaceMesh(): void {

    this.instruction = "Align your face with the camera"

    const videoElement = this.videoElement.nativeElement;
    const faceMesh: FaceMesh = new FaceMesh({
      locateFile: (file) => {
        return `https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/${file}`;
      },
    });

    faceMesh.setOptions({
      maxNumFaces: 1,
      refineLandmarks: true,
      minDetectionConfidence: 0.5,
      minTrackingConfidence: 0.5,
    });

    faceMesh.onResults(this.onFaceLandmarkResults);
    this.camera = MediaPipeUtil.startCamera(faceMesh, videoElement)

  }


  onFaceLandmarkResults = (results: any) => {

    const canvasElement = this.canvasElement.nativeElement;
    this.canvasCtx = canvasElement.getContext('2d') as CanvasRenderingContext2D;

    if (this.isCaptured) {
      return;
    }

    if (!results || !results.multiFaceLandmarks || !results.multiFaceLandmarks[0]) {
      this.isFaceDetected = false;
      this.resetMarks();
      return
    }

    const face = results.multiFaceLandmarks[0];
    const landMarks: [number, number, number][] = [];

    this.canvasCtx.save();
    this.canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
    this.canvasCtx.drawImage(results.image, 0, 0, canvasElement.width, canvasElement.height);

    face.forEach((landmark: any) => {
      const {x, y, z} = landmark;
      landMarks.push([x, y, z]);
    });

    // drawConnectors(this.canvasCtx, face, FACEMESH_TESSELATION, {
    //   color: 'rgba(255,255,255,0.16)',
    //   lineWidth: 0.5,
    // });

    this.canvasCtx.restore();
    this.detectMovement(landMarks);

  };


  private detectMovement(landMarks) {

    this.isFaceDetected = true;

    if (landMarks?.length < 1) {
      this.isFaceDetected = false;
      this.resetMarks()
      return;
    }


    this.distanceMessage = null;
    const faceDistance = MediaPipeUtil.getFaceDistance(landMarks);

    if (faceDistance < 3.5) {
      this.distanceMessage = "Come Closer"
      this.resetMarks();
      return;
    }
    if (faceDistance > 5) {
      this.distanceMessage = "Too Close"
      this.resetMarks();
      return;
    }

    const [pitch, yaw] = MediaPipeUtil.getFaceRotation(landMarks);

    if (pitch > 12) {
      this.isBottomActive = true
    }

    if (pitch < 7) {
      this.isTopActive = true
    }

    if (yaw < -30) {
      this.isRightActive = true
    }
    if (yaw > 30) {
      this.isLeftActive = true
    }


    //Message


    if (!this.isTopActive || !this.isBottomActive || !this.isLeftActive || !this.isRightActive) {
      this.instruction = "Move your head to complete the circle";
      return;
    }

    this.instruction = null;

    if (this.isTopActive && this.isBottomActive && this.isLeftActive && this.isRightActive) {
      this.isFaceRotationScanned = true;
      this.instruction = "Hold Still"
      this.startCountDown();
    }


  }


  startCountDown() {
    if (this.countDownInterval) {
      return;
    }
    this.countDownNumber = 1;
    this.countDownInterval = setInterval(() => {
      if (this.countDownNumber == 3) {
        this.capture();
        return;
      }
      this.countDownNumber++;
    }, 1000)
  }

  capture() {
    this.clearCountDownInterval()
    this.isCaptured = true;
    try {
      const videoElement = this.videoElement.nativeElement;
      const width = videoElement.videoWidth;
      const height = videoElement.videoHeight;
      const context = this.cameraResultCanvas.nativeElement.getContext('2d');

      this.cameraResultCanvas.nativeElement.width = width;
      this.cameraResultCanvas.nativeElement.height = height;
      context.translate(width, 0);
      context.scale(-1, 1);
      context.drawImage(videoElement, 0, 0, width, height);
      this.camera.stop();
      new Audio('assets/success-sound.mp3').play()
      setTimeout(() => {
        this.cameraCapturedImageData = this.cameraResultCanvas.nativeElement.toDataURL('image/png');
      }, 500)


    } catch (e) {
      console.log('QID | Error Capturing Image', e);
    }
  }

  clearCountDownInterval() {
    clearInterval(this.countDownInterval)
    this.countDownInterval = null;
  }

  resetMarks() {
    this.isFaceRotationScanned = false;
    this.isTopActive = false;
    this.isRightActive = false;
    this.isBottomActive = false;
    this.isLeftActive = false;
    this.isCaptured = false;
    this.clearCountDownInterval()
  }


  onStartScanClicked() {
    this.activeSlide = this.slides.scan;
    this.initializeFaceMesh();
  }

  onPhotoConfirmed() {
    this.onCapture.emit(this.cameraCapturedImageData)
  }

  onRetakeClicked(){
    this.isFaceDetected = false;
    this.cameraCapturedImageData = null;
    this.isCaptured = false;
    this.camera.start();
    this.resetMarks();
  }
}

import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { firstValueFrom, Observable, of, switchMap } from 'rxjs';
import { createFeatureStore } from '../../../utils/feature-store.util';
import { AccountType, AccountWiseRecords } from '@qid/core';
import { EntityState } from '@ngrx/entity';
import { OrganisationRelationship } from '../model/organisation-relationship.model';
import { CollectionTemplate } from '../../collection-template';
import { OrganisationRelationshipService } from './organisation-relationship.service';
import { AuthSelectors } from '../../authentication';

interface AdditionalAccountWiseData {
  parentOrganisation: OrganisationRelationship;
  parentCollectionTemplates: CollectionTemplate[];
  childOrganisations: OrganisationRelationship[];
}

const initialAccountWiseDataState = {
  parentOrganisation: null,
  parentCollectionTemplates: [],
  childOrganisations: []
};

export const OrgRelationshipStore =
  createFeatureStore<OrganisationRelationship,
    EntityState<AccountWiseRecords<OrganisationRelationship>>,
    AdditionalAccountWiseData>('organisationRelationship');

@Injectable()
export class OrganisationRelationshipFacade {


  private readonly apiService = inject(OrganisationRelationshipService);

  public activeAccount$ = this.store.select(OrgRelationshipStore.selectors.selectActiveAccount);

  private additionalAccountWiseData$: Observable<AdditionalAccountWiseData> = this.store.select(OrgRelationshipStore.selectors.selectActiveAccountAdditionalData);


  parentCollectionTemplates$: Observable<CollectionTemplate[]> = this.store.select(OrgRelationshipStore.selectors.selectActiveAccountAdditionalData).pipe(
    switchMap((data: AdditionalAccountWiseData) => {
      if (data) {
        return of(data.parentCollectionTemplates);
      }
      return of([]);
    })
  );

  childOrganisations$ = this.store.select(OrgRelationshipStore.selectors.selectActiveAccountRecords);

  parentBranchRealation$: Observable<OrganisationRelationship> = this.store.select(OrgRelationshipStore.selectors.selectActiveAccountAdditionalData).pipe(
    switchMap((data: AdditionalAccountWiseData) => {
      return data ? of(data.parentOrganisation) : of(null);
    })
  );

  constructor(private store: Store) {

  }

  async init() {
    if (!await firstValueFrom(this.activeAccount$)) {
      await this.addAccount();
    }
  }

  private async addAccount() {

    const account_id = await firstValueFrom(this.store.select(AuthSelectors.selectActiveAccountId));
    if (!account_id) {
      return;
    }
    const accountType = await firstValueFrom(this.store.select(AuthSelectors.selectActiveAccountType));

    const accountBasedEntity: AccountWiseRecords<OrganisationRelationship> = OrgRelationshipStore.createAccount(account_id, accountType, initialAccountWiseDataState);

    this.store.dispatch(OrgRelationshipStore.actions.addAccount({ accountBasedEntity: accountBasedEntity }));
  }

  async initSharedTemplates() {
    await this.init();
    return this.setSharedTemplates();
  }

  async setSharedTemplates() {
    const account_id = await firstValueFrom(this.store.select(AuthSelectors.selectActiveAccountId));
    const res = await this.apiService.getParentTemplates(account_id);
    if (res && res.data) {
      const currentData = await firstValueFrom(this.additionalAccountWiseData$);
      this.store.dispatch(OrgRelationshipStore.actions.loadAdditionalDataAccountSuccess({
        data: {
          ...currentData,
          parentCollectionTemplates: res.data
        }, account_id: account_id
      }));
    }
  }

  async initParentBranch() {
    await this.init();
    return this.setParentBranch();
  }

  async setParentBranch() {
    const account_id = await firstValueFrom(this.store.select(AuthSelectors.selectActiveAccountId));
    const res = await this.apiService.getParent();
    if (res && res.data) {
      const currentData = await firstValueFrom(this.additionalAccountWiseData$);
      this.store.dispatch(OrgRelationshipStore.actions.loadAdditionalDataAccountSuccess({
        data: {
          ...currentData,
          parentOrganisation: res.data
        }, account_id: account_id
      }));
    }
  }

  async refresh() {
    const account_id = await firstValueFrom(this.store.select(AuthSelectors.selectActiveAccountId));
    this.store.dispatch(OrgRelationshipStore.actions.loadRecordsForAccount({
      account_id: account_id, accountType: AccountType.organisation
    }));
  }


}

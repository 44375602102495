import {Component} from '@angular/core';
import {OrganisationRelationship} from "../../model/organisation-relationship.model";
import {OrganisationRelationshipService} from "../../services/organisation-relationship.service";
import {ModalController} from "@ionic/angular";


@Component({
  selector: 'edit-organisation-relationship-page',
  templateUrl: './edit-organisation-relationship-page.component.html',
  styleUrls: ['./edit-organisation-relationship-page.component.scss'],
})
export class EditOrganisationRelationshipPage {

  orgRelationship: OrganisationRelationship;

  constructor(private orgRelationshipService: OrganisationRelationshipService,
              private modalController: ModalController
  ) {

  }

  removeRelation = async () => {
    await this.orgRelationshipService.delete(this.orgRelationship._id)
    this.modalController.dismiss({deleted: true})
  }

}

import {NgModule} from '@angular/core';
import {GatewayWelcomeComponent} from './gateway-welcome/gateway-welcome.component';
import {SexyModule} from '@qid/sexy';
import {SuperModule} from '@qid/super';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {FormsModule} from '@angular/forms';
import {GatewayAuthenticationComponent} from './gateway-authentication/gateway-authentication.component';
import {GatewayDocumentSubmissionComponent} from "./gateway-document-submission/gateway-document-submission.component";
import {GatewayFormComponent} from './gateway-form/gateway-form.component';
import {GatewaySignatureComponent} from './gateway-signature/gateway-signature.component';
import {CommonComponentsModule, SignatureComponentsModule} from '@qid/common';
import {GatewayPoliciesComponent} from './gateway-policies/gateway-policies.component';
import {CollectionTemplateComponentModule} from '@qid/common';
import {GatewayContinuePreviousComponent} from './gateway-continue-previous/gateway-continue-previous.component';
import {GatewaySuccessfulComponent} from "./gateway-successful/gateway-successful.component";
import {ShareParticipantDocumentsComponent} from "./share-participant-documents/share-participant-documents.component";
import {ShareParticipantFormComponent} from "./share-participant-form/share-participant-form.component";
import {IdComponentsModule} from "@qid/common";
import {AuthenticationModule, UserComponentsModule} from "@qid/common";

@NgModule({
    imports: [
        IonicModule,
        CommonModule,
        FormsModule,
        SexyModule,
        SuperModule,
        AuthenticationModule,
        SignatureComponentsModule,
        CollectionTemplateComponentModule,
        UserComponentsModule,
        IdComponentsModule,
        CommonComponentsModule,
    ],
  declarations: [
    GatewayWelcomeComponent,
    GatewayAuthenticationComponent,
    GatewayDocumentSubmissionComponent,
    GatewayFormComponent,
    GatewaySignatureComponent,
    GatewayPoliciesComponent,
    GatewayContinuePreviousComponent,
    GatewaySuccessfulComponent,
    ShareParticipantDocumentsComponent,
    ShareParticipantFormComponent,
  ],
  exports: [
    GatewayWelcomeComponent,
    GatewayAuthenticationComponent,
    GatewayDocumentSubmissionComponent,
    GatewayFormComponent,
    GatewaySignatureComponent,
    GatewayPoliciesComponent,
    GatewayContinuePreviousComponent,
    GatewaySuccessfulComponent,
    ShareParticipantDocumentsComponent,
    ShareParticipantFormComponent,
  ],
})
export class GatewayComponentsModule {
}

<ion-header mode="ios">
  <ion-toolbar>
    <ion-button fill="clear" (click)="back()">Back</ion-button>
    <ion-title>qid</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>

  <div #img id='image' sxMatchParent sxCenterize='col'>
    <img sxWidth="full" [src]="getUrl()">
<!--    <div id="result" class="img-zoom-result"></div>-->
  </div>
<!--  <div class="img-zoom-container">-->
<!--    <img #img [src]="getUrl()"-->
<!--         (mousemove)="moveLens($event)" (touchmove)="moveLens($event)" />-->
<!--    <div #lens class="img-zoom-lens"-->
<!--         (mousemove)="moveLens($event)" (touchmove)="moveLens($event)"></div>-->
<!--    <div #result class="img-zoom-result"></div>-->
<!--  </div>-->



</ion-content>

import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {TextUtil, ValidationUtil} from "@qid/core";
import {Store} from "@ngrx/store";
import {User} from "../../model/User";
import {CreateUserDto} from "../../dto/create-user.dto";
import {UserService} from "../../services/user.service";
import {OtpVerifier} from "../../model/OtpVerifier";
import {OtpChannel} from "../../enum/OtpChannel";
import {UsersSelectors} from "../../../index";
import {AuthenticationService} from "../../../../authentication";


@Component({
  selector: "quick-signup",
  templateUrl: "./quick-signup.component.html",
  styleUrls: ["./quick-signup.component.scss"]
})
export class QuickSignupComponent implements OnInit {

  @Input() defaultEmail: string = null
  @Input() defaultName: string = null

  slides = {
    loggedIn: "loggedIn",
    inputEmail: "inputEmail",
    inputName: "inputName",
    otpOptions: "otpOptions",
    loading: "loading",
    defaultCredential: "defaultCredential"
  };
  activeSlide: any = this.slides.loading;

  @Output() onLogin: EventEmitter<User> = new EventEmitter<User>();
  public createUserDto: CreateUserDto = new CreateUserDto();
  public authenticatedUser$ = this.store.select(UsersSelectors.selectAuthenticatedUser);

  defaultCredentialUser: User;

  constructor(
    private userService: UserService,
    private store: Store,
    private authenticationService: AuthenticationService
  ) {
    this.createUserDto.phoneVerifier = new OtpVerifier(OtpChannel.phone);
    this.createUserDto.emailVerifier = new OtpVerifier(OtpChannel.email);
  }

  ngOnInit() {
    this.checkLoginStatus();
  }

  checkLoginStatus() {
    this.authenticationService.checkPreviousAuthenticatedUser().then(
      token => {
        this.onLoginStatusChecked(token)
      }
    )
  }

  async onLoginStatusChecked(token: string) {

    const isDefaultCredentials = ValidationUtil.isEmailValid(this.defaultEmail)

    if (!token && !isDefaultCredentials) {
      return this.goToSlide(this.slides.inputEmail);
    }

    if (!token && isDefaultCredentials) {
      return this.loginFlowForDefaultCredential();
    }

    if (token && !isDefaultCredentials) {
      return this.goToSlide(this.slides.loggedIn);
    }

    if (token && isDefaultCredentials) {

      this.authenticatedUser$.subscribe((user) => {
        if (!user) {
          return
        }
        if (user.email == this.defaultEmail) {
          return this.goToSlide(this.slides.loggedIn)
        }
        this.loginFlowForDefaultCredential()
      })


    }

  }

  async loginFlowForDefaultCredential() {

    this.createUserDto.emailVerifier.receiverId = this.defaultEmail

    const checkEmailUser = await this.userService.checkEmail(this.defaultEmail);

    if (checkEmailUser) {
      this.defaultCredentialUser = checkEmailUser
      this.goToSlide(this.slides.defaultCredential);
      return
    }

    this.createUserDto.name = this.defaultName

    await this.quickSignup(false)


  }

  async useOtherAccount() {
    await this.authenticationService.signOut();
    this.goToSlide(this.slides.inputEmail);
  }

  public goToSlide(slide: string) {
    this.activeSlide = slide;
  }

  public quickSignup = async (emitLogin = true) => {
    ValidationUtil.validateName(this.createUserDto.name);
    ValidationUtil.validateEmail(this.createUserDto.emailVerifier.receiverId);

    if (this.createUserDto.phoneVerifier?.receiverId?.length > 0) {
      ValidationUtil.validatePhone(this.createUserDto.phoneVerifier.receiverId);
    }

    this.createUserDto.name = this.createUserDto.name.trim()

    const isLoggedIn: boolean = await this.authenticationService.quickSignUp(this.createUserDto);

    if (emitLogin && isLoggedIn) {
      await this.login();
    }
  };

  public onEmail = async () => {
    if (
      !ValidationUtil.isEmailValid(this.createUserDto.emailVerifier.receiverId)
    ) {
      return;
    }

    await this.authenticationService.signInAnonymouslyIfNoUser();

    const user = await this.userService.checkEmail(
      this.createUserDto.emailVerifier.receiverId
    );

    if (!user) {
      this.activeSlide = this.slides.inputName;
      return;
    }

    //TODO Send Mobile OTP Option
    // if (user.phoneNumber) {
    //   this.createUserDto.phoneVerifier.receiverId = user.phoneNumber;
    //   this.createUserDto.phoneVerifier.countryCode = user.countryCode;
    //   this.activeSlide = this.slides.otpOptions;
    //   return;
    // }

    return this.sendOtpAndLogin(this.createUserDto.emailVerifier);
  };

  public sendOtpAndLogin = async (otpVerifier: OtpVerifier) => {

    const res = await this.authenticationService.signInWithOtp(otpVerifier.receiverId, otpVerifier.channel)

    if (res) {
      return this.login();
    }

  };

  public async login() {
    return this.emitLogin();
  }

  public getPhoneMasked(phone: string) {
    return TextUtil.getPhoneNumberMasked(phone);
  }

  public onContinueAsLoggedInUserClicked = async () => {
    return this.emitLogin();
  }

  emitLogin = async () => {
    return this.onLogin.emit();
  }


}

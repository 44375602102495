import {NgModule} from '@angular/core';
import {IonicModule} from '@ionic/angular';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {NgOtpInputModule} from "ng-otp-input";
import {MobileUpdateComponent} from "./mobile-update/mobile-update.component";
import {EmailUpdateComponent} from "./email-update/email-update.component";
import {OtpVerifyComponent} from "./otp-verify/otp-verify.component";
import {SexyModule} from "@qid/sexy";
import {SuperModule} from "@qid/super";
import {QuickSignupComponent} from "./quick-signup/quick-signup.component";

@NgModule({
  imports: [IonicModule, CommonModule, FormsModule, NgOtpInputModule, SexyModule, SuperModule],
  declarations: [
    MobileUpdateComponent, EmailUpdateComponent,
      OtpVerifyComponent,
      QuickSignupComponent
  ],
    exports: [EmailUpdateComponent, OtpVerifyComponent, QuickSignupComponent
  ],
})
export class UserComponentsModule {
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GatewayPageModule} from "./pages/gateway/gateway-page.module";
import {GatewayComponentsModule} from "./components/gateway-components.module";

@NgModule({
  imports: [
    CommonModule,
    GatewayPageModule,
    GatewayComponentsModule
  ],
})
export class GatewayModule {
}

import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ImageUtil} from "@qid/core";
import {Share, ShareParticipantUtil, ShareService} from "../../modules/share";
import {Account} from "../../interface/account.interface";

@Component({
  selector: 'verified-signup',
  templateUrl: './verified-signup.component.html',
  styleUrls: ['./verified-signup.component.scss'],
})
export class VerifiedSignupComponent {

  @Input() share: Share
  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>()
  public mainParticipant: Account;

  constructor(private shareService: ShareService) {
  }

  public init() {
    this.mainParticipant = ShareParticipantUtil.findMainParticipant(this.share?.participants)?.account;
    console.log(this.mainParticipant)
  }

  onCaptured(imageData: string) {

    let image: any = {dataUrl: imageData}

    const file = ImageUtil.dataURLtoFile(
      image.dataUrl,
      new Date().getTime() + '.' + image.format
    );

    this.uploadPhoto(file)
  }

  async uploadPhoto(file) {
    const res = await this.shareService.uploadFacePhoto(file, null)
    this.share.participants[1].facePhoto = {
      isLive : true,
      imageUrl : res.data.image
    }
    await this.shareService.update(this.share._id,{
      participants : this.share.participants,
      updateRemark: "Selfie Verification Submitted",
    })
    this.onSubmit.emit()
  }

}

<div
  *ngIf="activeSlide == slides.loading"
  sxWidth="full"
  sxHeight="full"
  sxCol
  sxAlign="center"
  sxDistribution="center">

  <ion-spinner></ion-spinner>
</div>
<div
  sxWidth="full"
  sxHeight="full"
  sxPadding="medium"
  sxCol
  sxGap="10px"
  sxAlign="stretch"
  sxDistribution="center"
  *ngIf="activeSlide == slides.loggedIn">

  <div *ngIf="authenticatedUser$ | async  as user" sxCol sxGap="10px" sxAlign="center" sxWidth="full">

    <div sxWidth="full" sxBackground="light" sxRadius="medium" sxCol sxAlign="center"
         sxGap="10px" sxPadding="medium" sxBorderColor="one" sxBorderWidth="1px">
      <p>You're logged in as</p>
      <avatar-round [title]="user?.name" [imageUrl]="user.displayPictureUrl"></avatar-round>
      <h1 sxMargin="0" sxTextAlign="center" sxCapitalize>{{user.name?.toLowerCase()}}</h1>
      <p sxColor="two" sxTextSize="medium" sxMargin="0" *ngIf="user?.email">{{user.email}}</p>
      <super-button sxWidth="full" sxMargin="10px 0 20px" [action]="onContinueAsLoggedInUserClicked"
                    iconEnd="chevron-forward-outline">
        Continue
      </super-button>
    </div>


    <super-button sxWidth="full" iconEnd="log-out-outline"
                  (click)="useOtherAccount()" *ngIf="user && !defaultEmail" type="outline">
      Logout
    </super-button>
  </div>

</div>

<div
  sxWidth="full"
  sxHeight="full"
  sxPadding="medium"
  sxCol
  sxGap="10px"
  sxAlign="stretch"
  sxDistribution="center"
  *ngIf="activeSlide == slides.defaultCredential">

  <div *ngIf="defaultCredentialUser" sxCol sxGap="10px" sxAlign="center">

    <avatar-round [title]="defaultCredentialUser?.name"
                  [imageUrl]="defaultCredentialUser.displayPictureUrl"></avatar-round>

    <p>Send OTP</p>
    <super-button sxWidth="full" [action]="onEmail" iconEnd="chevron-forward-outline">
      {{ "Continue as " + defaultCredentialUser.name }}
    </super-button>
  </div>

</div>

<div *ngIf="activeSlide == slides.inputEmail"
     sxCol
     sxGap="10px"
     sxWidth="fill"
     sxHeight="full"
     sxAlign="stretch"
     sxPadding="medium">

  <div sxHeight="50px"></div>
  <super-input
    [(value)]="createUserDto.emailVerifier.receiverId"
    type="email"
    label="Enter Email"
  ></super-input>
  <super-button [action]="onEmail">Next</super-button>
</div>

<div *ngIf="activeSlide == slides.inputName"
     sxCol
     sxGap="10px"
     sxWidth="fill"
     sxHeight="full"
     sxAlign="stretch"
     sxPadding="medium">

  <div sxHeight="50px"></div>
  <super-input
    [(value)]="createUserDto.name"
    label="Full Name"
  ></super-input>
  <super-input
    [(value)]="createUserDto.phoneVerifier.receiverId"
    type="phone"
    fixedCountry="India"
    countryCode="+91"
    label="Phone Number"
  ></super-input>
  <super-button [showError]="true" [action]="quickSignup">Next</super-button>
  <p class="terms-label">
    By continuing, you agree to our
    <a href="https://oneqid.com/privacy-policy/">Privacy policy</a> &
    <a href="https://oneqid.com/terms-of-use/">Terms of Use</a>
  </p>
</div>

<div
  *ngIf="activeSlide == slides.otpOptions"
  sxMatchParent
  sxCol
  sxDistribution="center"
  sxGap="10px"
  sxPadding="medium"
  sxAlign="stretch">

  <h1 sxMargin="0 5px">Send Otp on</h1>

  <super-button
    background="light"
    textAlign="start"
    iconEnd="chevron-forward"
    [action]="sendOtpAndLogin.bind(this, createUserDto.emailVerifier)"
  >{{ createUserDto.emailVerifier.receiverId }}</super-button>

  <super-button
    background="light"
    textAlign="start"
    iconEnd="chevron-forward"
    [action]="sendOtpAndLogin.bind(this, createUserDto.phoneVerifier)">
    {{getPhoneMasked(createUserDto?.phoneVerifier?.receiverId)}}</super-button>
</div>

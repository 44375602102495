import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import PinchZoom from 'pinch-zoom-js';
import { ImageUtil } from '@qid/core';

@Component({
  selector: 'app-pinch-to-zoom',
  templateUrl: './pinch-to-zoom.component.html',
  styleUrls: ['./pinch-to-zoom.component.scss'],
})
export class PinchToZoomComponent implements AfterViewInit {
  @ViewChild('img', { static: false }) img: ElementRef<HTMLImageElement>;
  @ViewChild('lens', { static: false }) lens: ElementRef<HTMLDivElement>;
  @ViewChild('result', { static: false }) result: ElementRef<HTMLDivElement>;

  @Input() imageUrl: string = '';
  @Input() autoBase: boolean = true;
  cx: number;
  cy: number;

  constructor(private modalController: ModalController) {}


  ionViewDidEnter() {
    this.enablePinchToZoom();
  }

  ngAfterViewInit(): void {
    // this.setupZoom();
  }



  setupZoom() {
    const img = this.img.nativeElement;
    const lens = this.lens.nativeElement;
    const result = this.result.nativeElement;
    this.cx = result.offsetWidth / lens.offsetWidth;
    this.cy = result.offsetHeight / lens.offsetHeight;
    const backgroundImageUrl = this.getUrl();
    result.style.backgroundImage = `url('${backgroundImageUrl}')`;
    result.style.backgroundSize = `${img.width * this.cx}px ${img.height * this.cy}px`;
  }
  moveLens(event: MouseEvent | TouchEvent): void {
    const img = this.img.nativeElement;
    const result = this.result.nativeElement;
    event.preventDefault();
    const pos = this.getCursorPos(event, img);
    let x = pos.x; // Use positive value for x
    let y = pos.y; // Use positive value for y
    x = Math.max(0, Math.min(x, img.width));
    y = Math.max(0, Math.min(y, img.height));
    const offsetX = x / img.width;
    const offsetY = y / img.height;
    const backgroundX = -offsetX * result.offsetWidth;
    const backgroundY = -offsetY * result.offsetHeight;
    result.style.backgroundPosition = `${backgroundX}px ${backgroundY}px`;
  }

  getCursorPos(event: MouseEvent | TouchEvent, img: HTMLImageElement) {
    const rect = img.getBoundingClientRect();
    let x = 0, y = 0;

    if (event instanceof MouseEvent) {
      x = event.pageX - rect.left;
      y = event.pageY - rect.top;
    } else if (event instanceof TouchEvent) {
      x = event.touches[0].pageX - rect.left;
      y = event.touches[0].pageY - rect.top;
    }

    x = x - window.scrollX;
    y = y - window.scrollY;

    return { x, y };
  }

  enablePinchToZoom() {
    if (!this.img) return;

    const pz = new PinchZoom(this.img.nativeElement);
    pz.enable();
  }

  getUrl() {
    return this.autoBase ? ImageUtil.getImageSource(this.imageUrl) : this.imageUrl;
  }

  back() {
    this.modalController.dismiss();
  }
}

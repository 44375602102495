import {NgModule} from "@angular/core";
import {TemplateSelectorComponent} from "./template-selector/template-selector.component";
import {IonicModule} from "@ionic/angular";
import {CommonModule} from "@angular/common";
import {SexyModule} from "@qid/sexy";
import {WebcamModule} from 'ngx-webcam';
import {SuperModule} from "@qid/super";
import {FormsModule} from "@angular/forms";
import {VerifiedSignupComponent} from "./verified-signup/verified-signup.component";
import {MediaPipePlaygroundComponent} from "./media-pipe-playground/media-pipe-playground.component";

@NgModule({
  imports: [IonicModule, CommonModule, SexyModule,
    WebcamModule, SuperModule, FormsModule
  ],
  declarations: [TemplateSelectorComponent,
    MediaPipePlaygroundComponent, VerifiedSignupComponent],
  exports: [TemplateSelectorComponent,
    MediaPipePlaygroundComponent, VerifiedSignupComponent]
})
export class CommonComponentsModule {

}

import {OtpVerifier} from '../model/OtpVerifier';

export class CreateUserDto {
  name: string;
  phoneVerifier: OtpVerifier;
  emailVerifier: OtpVerifier;
  uid: string;
  createdOn: Date;
  createdByScan: any;
  createdByDevice: string;
  country: string;

  constructor() {
  }
}

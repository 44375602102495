import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {AlertController, ModalController,} from '@ionic/angular';
import {PageLoaderComponent} from '@qid/super';
import {Share} from '../../model/Share';
import {ShareService} from '../../services/share.service';
import {ApiModules, ApiOperations, ImageUtil} from '@qid/core';
import {Participant} from "../../model/participant";
import {ParticipantRoles} from "../../enum/participant-roles.enum";
import {SharesDataRepository} from "../../+state/shares-data-repository.service";
import {ExportPdfService} from "../../services/export-pdf.service";
import {Browser} from "@capacitor/browser";
import {User} from "../../../user";
import {Organisation} from "../../../organisation";
import {ID, ViewIdPage, ViewMultipleDocumentsPage} from "../../../id";

@Component({
  selector: 'app-view-share',
  templateUrl: './view-share.page.html',
  styleUrls: ['./view-share.page.scss'],
})
export class ViewSharePage implements OnInit {

  @ViewChild('pageLoaderComponent') pageLoaderComponent: PageLoaderComponent;
  @Input() shareId: string;

  public share: Share = null;
  public organiser: Participant;
  public secondParty: User | Organisation;
  public sharerParticipants: Participant[] = [];
  public participant : Participant;
  public baseIconUrl = 'assets/imgs/'

  constructor(
    private alertController: AlertController,
    private shareService: ShareService,
    private shareDataRepository: SharesDataRepository,
    private modalController: ModalController,
    private exportPdfService: ExportPdfService
  ) {
  }

  ngOnInit() {
  }

  ionViewDidEnter() {
    if (this.shareId) {
      this.onShareIdFetched();
    }
  }

  private async onShareIdFetched() {

    await this.fetchShare(this.shareId)

    this.sharerParticipants = []

    if (!this.share?.participants) {
      return
    }

    for (let participant of this.share.participants) {
      if (participant.roles.indexOf(ParticipantRoles.organiser) > -1) {
        this.organiser = participant;
        continue;
      }
      if (!participant.roles || participant.roles.indexOf(ParticipantRoles.sharer) > -1) {
        this.sharerParticipants.push(participant)
      }
    }

    this.pageLoaderComponent.hideLoader();
  }

  getParticipantRole(roles: string[]) {
    if (!roles) {
      return
    }
    if (roles.indexOf('organiser') > -1) {
      return 'Organiser'
    }

    return 'Participant'
  }

  //Api Calls

  async fetchShare(id: string) {
    if (!id) {
      return;
    }
    this.share = await this.shareService.read(id);
  }

  delete = async () => {
    try {
      await this.shareDataRepository.delete(this.share._id);
      this.modalController.dismiss()
    } catch (e) {
    }
  };


  //Component Actions

  async openViewIdPage(id: ID) {

    if (!id) {
      return
    }

    const modal = await this.modalController.create({
      component: ViewIdPage,
      componentProps: {
        id: id,
      },
      animated: false,
    });
    modal.present();
  }

  printAsPDF = async () => {
    await this.exportPdfService.exportIndividualPdf(this.share);
  };

  deleteShare = async () => {
    const alert = await this.alertController.create({
      header: 'Delete ID',
      message: 'Are you sure you want to delete this ID?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Delete',
          handler: async () => {
            await this.delete();
          },
        },
      ],
    });
    await alert.present();
    await alert.onDidDismiss();
  };

  async openViewAllDocumentsPage(document_ids: string[]) {
    const modal = await this.modalController.create({
      component: ViewMultipleDocumentsPage,
      componentProps: {
        document_ids: document_ids
      },
    });
    modal.present();
  }

  getImage(url: string) {
    return ImageUtil.getImageSource(url);
  }

  closeModal() {
    this.modalController.dismiss()
  }

  async openWhatsapp(participant: User | Organisation | any) {
    await Browser.open({
      url: `https://wa.me/${participant?.phoneNumber}`
    });
  }

  protected readonly ApiOperations = ApiOperations;
  protected readonly ApiModules = ApiModules;
}
